import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Search } = Input;

var token= ''
var sacco= ''
var bizuserid= ''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }


   
    render() {
      return (
        <div style={{padding:20}}>
           <style>
            {`@media print {
            @page { size: landscape; }
             }`}
          </style>
       <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>
       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOAN AGING REPORT AS OF {this.props.date} </h3>
            
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Loan number</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Issuance Date</th>
                            <th>Loan Amount</th>
                            <th>Arears</th>
                            <th>Outstanding Balance</th>
                            <th>(0) days</th>
                            <th>(1-30) Days</th>
                            <th>(31-60) Days</th>
                            <th>(61-90) Days</th>
                            <th>(91-120) Days</th>
                            <th> {">"} 120 Days</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.loan_number}</td>
                            <td>{item.account_number}</td>
                            <td>{item.account_name}</td>
                            <td>{item.loan_date}</td>
                            <td>{<CurrencyFormat value={item.total_loan_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.arrears} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.outstanding_balance} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_zero} displayType={'text'} thousandSeparator={true}/>}</td>                            

                            <td>{<CurrencyFormat value={item.days_1_30} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_31_60} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_61_90} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_91_120} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_greater_120} displayType={'text'} thousandSeparator={true}/>}</td>                            

                            </tr>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.percentageZero} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totaldays_1_30} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totaldays_31_60} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totaldays_61_90} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totaldays_91_120} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.percentagpercentagegreaterthan120eSix} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>

                          <tr>
                          <td style={{fontWeight:'bolder'}}>
                          <h4>Total risk: {<CurrencyFormat value={this.props.totalRiskAmount} displayType={'text'} thousandSeparator={true}/>}</h4>

                          </td>
                          <td>
                          <h4>Portfolio total: {<CurrencyFormat value={this.props.portifoliototal} displayType={'text'} thousandSeparator={true}/>}</h4>

                          </td>
                          <td>
                          <h4>Percentage off portifolio: {<CurrencyFormat value={this.props.percentageoff_portfolio.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</h4>

                          </td>
                          <td>

                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bolder'}}></td>

                          </tr>
                          
                      </tbody>
                  </reactstrp.Table>       
           
                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   

        </div>
      );
    }
  }


class LoanAgingReport extends React.Component {

  state = {
    loanissues:[],
    //dateone:moment().format(dateFormat).toString(),
    datarequested:true,
    totalsaggregate:{},
    totalRiskAmount:0,
    percentageoff_portfolio:0,
    portifoliototal:0,
    loanissues_placeholder:[],


    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),

   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('sacco', sacco);

    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getLoanAgingReport', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({loanissues:JSON.parse(res.data.loanissues)})
      this.setState({totalsaggregate:JSON.parse(res.data.loanissuesaggregate)})
      this.setState({totalRiskAmount:res.data.totalRisk})

      this.setState({portifoliototal:res.data.portifoliototal})
      this.setState({percentageoff_portfolio:res.data.percentageoff_portfolio})

      this.setState({loanissues_placeholder:JSON.parse(res.data.loanissues)})


    } 
    )
    .catch(error => console.log(error)) 

  }
 

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

  //calculate




   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {

       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>             </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none",}}>
               &nbsp;&nbsp;
              <ReportToPrint
              date={this.state.dateone} 
              loanissues={this.state.loanissues} 
              totalsaggregate={this.state.totalsaggregate} 
              totalRiskAmount={this.state.totalRiskAmount} 
              percentageoff_portfolio={this.state.percentageoff_portfolio} 
              portifoliototal={this.state.portifoliototal} 

              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="LOAN AGING REPORT" key="1">
           
              <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
              <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />


              &nbsp;&nbsp;&nbsp;
              <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>}>Download loan aging report (Excel)</Button>}>
                <ExcelSheet data={this.state.loanissues} name="Loan aging report">

                    <ExcelColumn label="Loan number" value="loan_number"/>
                    <ExcelColumn label="Account name" value="account_name"/>
                    <ExcelColumn label="Account number" value="account_number"/>
                    <ExcelColumn label="Issuance date" value="loan_date"/>
                    <ExcelColumn label="Loan Amount" value="total_loan_amount"/>
                    
                    <ExcelColumn label="Arrears" value="arrears"/>
                    <ExcelColumn label="Outstanding Balance" value="outstanding_balance"/>
                    <ExcelColumn label="(0) days" value="days_zero"/>

                    <ExcelColumn label="(1-30) Days " value="days_1_30"/>
                    <ExcelColumn label="(31-60) Days " value="days_31_60"/>

                    <ExcelColumn label="(61-90) Days " value="days_61_90"/>
                    <ExcelColumn label="(91-120) Days " value="days_91_120"/>
                    <ExcelColumn label="> 120 Days " value="days_greater_120"/>

                </ExcelSheet>
               </ExcelFile>
               &nbsp;&nbsp;&nbsp;


               <Form  layout="inline" >
              
              <FormItem label="Date Range (Issuance Period)">
                  <RangePicker defaultValue={moment()} onChange={(date, dateString) =>{
                      this.setState({ dateone: dateString[0]});
                      this.setState({ datetwo: dateString[1]});
                    }} format={dateFormat} />
              </FormItem>


            <FormItem>
            <Button onClick={()=>{
                this.setState({loanissues:[]})

                if(this.state.dateone==='' || this.state.datetwo===''){
                  alert("Date one or two missing")
                  }else{
                    this.setState({datarequested:true})

                let form_data = new FormData();
                form_data.append('sacco', sacco);
                form_data.append('dateone', this.state.dateone);
                form_data.append('datetwo', this.state.datetwo);
  
                //Now submit sale data to database
                axios.post(serverconfig.backendserverurl+'/customqueries/getLoanAgingReport_daterange', form_data,{
                headers: {
                  'content-type': 'multipart/form-data'
                }
                })
                .then(res =>{
                  this.setState({datarequested:false})
                  this.setState({loanissues:JSON.parse(res.data.loanissues)})
                  this.setState({totalsaggregate:JSON.parse(res.data.loanissuesaggregate)})
                  this.setState({totalRiskAmount:res.data.totalRisk})
            
                  this.setState({portifoliototal:res.data.portifoliototal})
                  this.setState({percentageoff_portfolio:res.data.percentageoff_portfolio})
            
                  this.setState({loanissues_placeholder:JSON.parse(res.data.loanissues)})
                            } 
                )
                .catch(error => console.log(error)) 
              }


            }} type="primary" htmlType="button">Search</Button>
            </FormItem> 

            </Form>

              </div>  
              <br></br>

              <div style={{overflowX:'auto'}}>


              <Search
            placeholder="Input Account number or Account name"
            onChange={(value) => {
              var val=value.target.value
              console.log(val)
              if (val !== '') {
                const results = this.state.loanissues.filter((request) => {
                  return request.account_name.toLowerCase().startsWith(val.toLowerCase());
                  // Use the toLowerCase() method to make it case-insensitive
                });
          
                if (results.length === 0) {
                  // this.setState({loanissues:this.state.loanissues_placeholder});

                  const results_2 = this.state.loanissues.filter((request) => {
                    return request.account_number.toLowerCase().startsWith(val.toLowerCase());
                    // Use the toLowerCase() method to make it case-insensitive
                  });

                  if (results_2.length === 0) {
                    this.setState({loanissues:this.state.loanissues_placeholder});

                  }else{
                    this.setState({loanissues:results_2});

                  }

                } else {
                  this.setState({loanissues:results});
                  console.log(results)
                }
          
              } else {
                this.setState({loanissues:this.state.loanissues_placeholder});
          
              }
          
            }}
            style={{
              width: 400,
            }}
            />
            <br></br>
            <br></br>




              <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Loan number</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Issuance Date</th>
                            <th>Loan Amount</th>
                            <th>Arears</th>
                            <th>Outstanding Balance</th>
                            <th>(0) days</th>
                            <th>(1-30) Days</th>
                            <th>(31-60) Days</th>
                            <th>(61-90) Days</th>
                            <th>(91-120) Days</th>
                            <th> {'>'} 120 Days</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.loan_number}</td>
                            <td>{item.account_number}</td>
                            <td>{item.account_name}</td>
                            <td>{item.loan_date}</td>
                            <td>{<CurrencyFormat value={item.total_loan_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.arrears} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.outstanding_balance} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_zero} displayType={'text'} thousandSeparator={true}/>}</td>                            

                            <td>{<CurrencyFormat value={item.days_1_30} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_31_60} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_61_90} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_91_120} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={item.days_greater_120} displayType={'text'} thousandSeparator={true}/>}</td>                            

                            </tr>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.percentageZero} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totaldays_1_30} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totaldays_31_60} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totaldays_61_90} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totaldays_91_120} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.percentagpercentagegreaterthan120eSix} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>

                          <tr>
                          <td style={{fontWeight:'bolder'}}>
                          <h4>Total risk: {<CurrencyFormat value={this.state.totalRiskAmount} displayType={'text'} thousandSeparator={true}/>}</h4>

                          </td>
                          <td>
                          <h4>Portfolio total: {<CurrencyFormat value={this.state.portifoliototal} displayType={'text'} thousandSeparator={true}/>}</h4>

                          </td>
                          <td>
                          <h4>Percentage off portifolio: {<CurrencyFormat value={this.state.percentageoff_portfolio.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</h4>

                          </td>
                          <td>

                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bolder'}}></td>

                          </tr>
                          
                      </tbody>
                  </reactstrp.Table>      
                </div>
                   
              <br></br>

              </Panel>
              </Collapse>

             </div>
  
      )        

      }
   
   }
}

export default LoanAgingReport; 
