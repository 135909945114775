import React from 'react';
import BankAccountForm from '../components/BankAccountForm'
import BankSafeTransferForm from '../components/BankSafeTransferForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Modal,Form } from 'antd';

import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}


var sacco= ''
var bizuserid= ''
var token= ''

class BankAccountList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    bankaccounts: [],
    datarequested:true,
    banktransfers: [],
    bankeditmodalvisible:false,
    selectedbankaccount:{},
    selectedbankaccountnumber:'',
    selectedbankname:''

  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/bankaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          bankaccounts:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/safebanktransfers/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          banktransfers:res.data
        })

        this.setState({datarequested:false})
    })

}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/bankaccounts/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {
    const columns = [
      {
        title: 'Bank Name',
        dataIndex: 'bankname',
        key: 'id',
        ...this.getColumnSearchProps('bankname'),
      },
      {
        title: 'Account Number',
        dataIndex: 'accountnumber',
        key: 'id',
        ...this.getColumnSearchProps('accountnumber'),
      },
      {
        title: 'Account Balance',
        dataIndex: 'balance',
        key: 'id',
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover
        content={<p>Click here to edit,view details</p>} title="Edit,View"
        >
          <FundViewOutlined style={{color:'blue'}} onClick={()=>{
            this.setState({bankeditmodalvisible:true})
            this.setState({selectedbankaccount:record})

            this.setState({selectedbankname:record.bankname})
            this.setState({selectedbankaccountnumber:record.accountnumber})

          }}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;

        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    const transfercolumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Transfer By',
        dataIndex: 'username',
        key: 'id',
        ...this.getColumnSearchProps('username'),
      },

      {
        title: 'Money From',
        dataIndex: 'money_from',
        key: 'id',
      },
      {
        title: 'Money To',
        dataIndex: 'money_to',
        key: 'id',
      },
      {
        title: 'Tranfer Amount',
        dataIndex: 'transfer_amount',
        key: 'id',
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL BANK ACCOUNTS" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.bankaccounts} 
            bordered/>
            </Panel>
            </Collapse>
            <br />

            <h3>Create New Account here</h3>
            <BankAccountForm
              requestType="post"
              assetID={null}
              btnText="Save Bank Account"
            />

            <h3>Bank Safe Transfers</h3>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="SAFE BANK TRANSFERS" key="1">
            <Table 
            columns={transfercolumns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.banktransfers} 
            bordered/>
            </Panel>
            </Collapse>
            <br></br>
            <h3>Create New Bank Transfer</h3>
            <BankSafeTransferForm
              requestType="post"
              assetID={null}
              btnText="Transfer"
            />


            <Modal
              visible={this.state.bankeditmodalvisible}
              title="Bank Account Edit"
              width={600}
              onCancel={()=>this.setState({bankeditmodalvisible:false})}
              footer={[
                <Button key="back" onClick={()=>this.setState({bankeditmodalvisible:false})}>
                  Cancel
                </Button>,
                  <Button key="back" type='primary' onClick={()=>{
                    this.setState({bankeditmodalvisible:false})

                    let form_data = new FormData();
                    form_data.append('bankname', this.state.selectedbankname);
                    form_data.append('accountnumber', this.state.selectedbankaccountnumber);
                
                      axios.put(serverconfig.backendserverurl+`/api/bankaccounts/${this.state.selectedbankaccount.id}/`, form_data, {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                      })
                    .then(res =>
                     window.location.reload(false)
                    )
                    .catch(error => console.log(error))

                  }}>
                  Update
                </Button>,
                ]}
                >
                  <FormItem label="Bank Name"
                  >
                    <Input  placeholder="Put a name here." value={this.state.selectedbankname} onChange={(val)=>{this.setState({selectedbankname:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Account Number" 
                  >
                    <Input  placeholder="Account Number" value={this.state.selectedbankaccountnumber} onChange={(val)=>this.setState({selectedbankaccountnumber:val.target.value})} />
                  </FormItem>
            </Modal>
        </div>
    )

    }

  }
}

export default BankAccountList; 
