import React from 'react';
import { Form, Input, Button,Select,Spin, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''

class UserSaccoAccountAttachmentForm extends React.Component {

  state = {
    companyaccounts:[],
    assetname:'' ,  
    saccoaccount:'',
    netbookvalue:0,
    assetphoto:null,
    details:'',
    datarequested:true,
    accounts: [],
    user_saccount:''


  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          companyaccounts:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

        this.setState({datarequested:false})

    })
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('saccoaccount', this.state.saccoaccount);
    form_data.append('user_saccount', this.state.user_saccount);
    form_data.append('sacco', sacco);
    
    axios.post(serverconfig.backendserverurl+'/customqueries/attach_usertosaccoaccount', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{
    message.info(res.data.message)
    this.setState({datarequested:false})
     window.location.reload(false)
  }        
  )
  .catch(error => console.log(error))

  }

  handleassetnameChange= (e) => this.setState({ assetname: e.target.value});
  handlesaccoaccountChange= (e) => this.setState({ saccoaccount: e});
  handlenetbookvalueChange= (e) => this.setState({ netbookvalue: e.target.value});
  handleassetphotoChange= (e) => this.setState({ assetphoto: e.target.files[0]});
  handledetailsChange= (e) => this.setState({ details: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          <h4>Attach users to sacco/company accounts</h4>
          <Form
            onFinish={(event) => this.handleFormSubmit(event)} 
          >
  
            <FormItem label="Sacco Account"
              name="saccoaccount"
              rules={[
                {
                  required: true,
                  message: 'Please select sacco account',
                },
              ]}           
            >
                <Select  name="saccoaccount" placeholder="Sacco Account" style={{ width: 120 }} value={this.state.saccoaccount} onChange={this.handlesaccoaccountChange} >
                  {this.state.companyaccounts.map(
                    (accnt)=>(
                      <Option  value={accnt.id}>{accnt.account_name}</Option>
                    ))}
                </Select>
            </FormItem>
  

            <FormItem label="User Account"
              name="user_saccount"
              rules={[
                {
                  required: true,
                  message: 'Please select user account',
                },
              ]}           
            >
                <Select  name="user_saccount" placeholder="Sacco Account" style={{ width: 120 }} value={this.state.user_saccount} onChange={(val)=>{this.setState({user_saccount:val})}} >
                  {this.state.accounts.map(
                    (accnt)=>(
                      <Option  value={accnt.id}>{accnt.username}</Option>
                    ))}
                </Select>
            </FormItem>
         
            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );

    }
  }

}


export default UserSaccoAccountAttachmentForm;



