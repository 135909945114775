import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Search } = Input;

var token= ''
var sacco= ''
var bizuserid= ''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

    }

    calculateTotalofPrinciple=()=>{
      var  total=0
  
      this.props.loanissues.map((item)=>{
        total+=Number(item.principle)
      })
  
      return total;
    }

    calcUltaeTotaltobepaid_tot=()=>{
      var  total=0
  
      this.props.loanissues.map((item)=>{
        total+=Number(item.total_tobe_paid)
      })
  
      return total;
  
  
    }


    render() {
      return (
        <div style={{padding:20}}>
          <style>
          {`@media print {
            @page { size: landscape; }
          }`}
          </style>
          <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
                
                  </Col>
       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOAN OUTSTANDING REPORT AS OF {this.props.date} </h3>
            
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                           <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Issuance Date</th>
                            <th>Loan Period</th>
                            <th>Principle Amount</th>
                            <th>Total(To be paid)</th>
                            <th>Principle Paid</th>
                            <th>Interest Paid</th>
                            <th>Fine Paid</th>
                            <th>Total Paid</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td>{item.loan_start_date}</td>
                            <td>{item.loan_period}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_tobe_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.fine} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalofPrinciple()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calcUltaeTotaltobepaid_tot()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totalPrinciplePaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totalInterestPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totalFinePaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totalsPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totalPrincipleBal} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totalInterestBal} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.totalBal} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>   

                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   
           
        </div>
      );
    }
  }


class OutStandingLoanReport extends React.Component {

  state = {
    loanissues:[],
    dateone:moment().format(dateFormat).toString(),
    datarequested:true,
    totalsaggregate:{},
    loanissues_placeholder:[],

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),


   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('sacco', sacco);

    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getOutStandingLoanReport', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({loanissues:JSON.parse(res.data.loanissues)})
      this.setState({totalsaggregate:JSON.parse(res.data.loanissuesaggregate)})

      this.setState({loanissues_placeholder:JSON.parse(res.data.loanissues)})

    } 
    )
    .catch(error => console.log(error)) 

  }
 

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  calculateTotalofPrinciple=()=>{
    var  total=0

    this.state.loanissues.map((item)=>{
      total+=Number(item.principle)
    })

    return total;
  }

  calcUltaeTotaltobepaid_tot=()=>{
    var  total=0

    this.state.loanissues.map((item)=>{
      total+=Number(item.total_tobe_paid)
    })

    return total;


  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {

       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>             </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none",}}>
               &nbsp;&nbsp;
              <ReportToPrint
              date={this.state.dateone} 
              loanissues={this.state.loanissues} 
              totalsaggregate={this.state.totalsaggregate} 
              ref={el => (this.componentRef = el)} /> 
            </div>

            <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}> 
              <ReactToPrint
                trigger={() => 
                <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                Print
                </Button>
                }
                content={() => this.componentRef}
                />
                           
              &nbsp;&nbsp;&nbsp;
              <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>}>Download report (Excel)</Button>}>
                <ExcelSheet data={this.state.loanissues} name="Outstanding loan report">
                    <ExcelColumn label="Account Name" value="accountName"/>
                    <ExcelColumn label="Account Number" value="accountNumber"/>
                    <ExcelColumn label="Issuance date" value="loan_start_date"/>
                    <ExcelColumn label="Loan period" value="loan_period"/>
                    <ExcelColumn label="Principle Amount" value="principle"/>
                    <ExcelColumn label="Total to be paid" value="total_tobe_paid"/>
                    <ExcelColumn label="Principle paid" value="principle_paid"/>
                    <ExcelColumn label="Interest paid" value="interest_paid"/>
                    <ExcelColumn label="Fine Paid " value="fine"/>
                    <ExcelColumn label="Total Paid " value="total_paid"/>
                    <ExcelColumn label="Principle balance " value="principle_balance"/>
                    <ExcelColumn label="Interest balance " value="interest_balance"/>
                    <ExcelColumn label="Total Balance " value="balance"/>

                </ExcelSheet>
            </ExcelFile>

            &nbsp;&nbsp;&nbsp;

            <Form  layout="inline" >
            
            <FormItem label="Date Range (Issuance Period)">
                <RangePicker onChange={(date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   }} format={dateFormat} />
            </FormItem>


            <FormItem>
            <Button onClick={()=>{
           

            if(this.state.dateone==='' || this.state.datetwo===''){
            alert("Date one or two missing")
            }else{
            this.setState({datarequested:true})

            let form_data1 = new FormData();
            form_data1.append('sacco', sacco);

            form_data1.append('dateone', this.state.dateone);
            form_data1.append('datetwo', this.state.datetwo);

            axios.post(serverconfig.backendserverurl+`/customqueries/getOutStandingLoanReport_byperiod`, form_data1)
            .then(res => {
              this.setState({loanissues:JSON.parse(res.data.loanissues)})
              this.setState({totalsaggregate:JSON.parse(res.data.loanissuesaggregate)})
        
              this.setState({loanissues_placeholder:JSON.parse(res.data.loanissues)})
        
              this.setState({datarequested:false})

            })

            }


            }} type="primary" htmlType="button">Search</Button>
            </FormItem> 
            </Form>
            </div> 
            <br></br>



            <Search
            placeholder="Input Account number or Account name"
            onChange={(value) => {
              var val=value.target.value
              console.log(val)
              if (val !== '') {
                const results = this.state.loanissues.filter((request) => {
                  return request.accountName.toLowerCase().startsWith(val.toLowerCase());
                  // Use the toLowerCase() method to make it case-insensitive
                });
          
                if (results.length === 0) {
                  // this.setState({loanissues:this.state.loanissues_placeholder});

                  const results_2 = this.state.loanissues.filter((request) => {
                    return request.accountNumber.toLowerCase().startsWith(val.toLowerCase());
                    // Use the toLowerCase() method to make it case-insensitive
                  });

                  if (results_2.length === 0) {
                    this.setState({loanissues:this.state.loanissues_placeholder});

                  }else{
                    this.setState({loanissues:results_2});

                  }

                } else {
                  this.setState({loanissues:results});
                  console.log(results)
                }
          
              } else {
                this.setState({loanissues:this.state.loanissues_placeholder});
          
              }
          
            }}
            style={{
              width: 400,
            }}
            />
            <br></br>
            <br></br>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="OUTSTANDING LOAN REPORT" key="1">
           
              <div style={{overflowX:'auto'}}>
              <reactstrp.Table bordered>
                        <thead>
                          <tr>
                           <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Issuance Date</th>
                            <th>Loan Period</th>
                            <th>Principle Amount</th>
                            <th>Total(To be paid)</th>
                            <th>Principle Paid</th>
                            <th>Interest Paid</th>
                            <th>Fine Paid</th>
                            <th>Total Paid</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td>{item.loan_start_date}</td>
                            <td>{item.loan_period}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_tobe_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.fine} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalofPrinciple()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calcUltaeTotaltobepaid_tot()} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totalPrinciplePaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totalInterestPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totalFinePaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totalsPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totalPrincipleBal} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totalInterestBal} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.totalBal} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                          

                      </tbody>
                  </reactstrp.Table>      
              </div>
                   
 
            </Panel>
            </Collapse>
          </div>
  
      )        

      }
   
   }
}

export default OutStandingLoanReport; 
