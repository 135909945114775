import React from 'react';

import axios from 'axios'
import { Table, Input, Button,Tooltip,Popover,Popconfirm,message,Descriptions,Modal,Spin,Tabs,Collapse,Form } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,MailFilled,DownloadOutlined,PlusOutlined,PrinterOutlined,FundViewOutlined,EditOutlined,CloseCircleOutlined,WalletFilled,LoadingOutlined,FilePdfFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import  LoanImportForm from '../components/LoanImportForm'
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import  LoanRepaymentDirectViewList from './LoanRepaymentDirectViewList'
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function callback(key) {
  console.log(key);
}

const { TabPane } = Tabs;
const FormItem=Form.Item;

var sacco= ''
var bizuserid= ''
var token= ''
var username=''


class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

//calculate principleTotal
calculateScheduleTotal=()=>{
  var principleTotal=0
  this.props.loanschedule.map(
    (item)=>{
      principleTotal+=Number(item.principle)
    })

  return principleTotal;
}

  //calculate principleTotal
  calculateInterestTotal=()=>{
    var InterestTotal=0
    this.props.loanschedule.map(
      (item)=>{
        InterestTotal+=Number(item.interest)
      })

    return InterestTotal;
  }

  //calculate principleTotal
  calculateTotalPay=()=>{
    var TotalPay=0
    this.props.loanschedule.map(
      (item)=>{
        TotalPay+=Number(item.total)
      })

    return TotalPay;
  }



  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> LOAN NUMBER:  {this.props.loanissueobject.loan_number} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>GRACE PERIOD: {this.props.loanissueobject.grace_period} DAYS</h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

           <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Principle Bf</th>
                            <th>Principle Pay</th>
                            <th>Interest Pay</th>
                            <th>Total Pay</th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>   

                                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>        

    </div>
    );
  }
}


class StatementToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }
 //calculate principle paid
 calculateTotalPrinciplePaid=()=>{
  var TotalPay=0
  this.props.loanrepayments.map(
    (item)=>{
      TotalPay+=Number(item.principle_paid)
    })

  return TotalPay;
 }

  //calculate Interest paid
  calculateTotalInterestPaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.interest_paid)
      })

    return TotalPay;
   }

         //calculate Total total paid
  calculateTotalTotalPaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.total_paid)
      })

    return TotalPay;
   }

   //calculate total fine paid
   calculateTotalFinePaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.fine_paid)
      })

    return TotalPay;
   }


  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN STATEMENT </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> LOAN NUMBER:  {this.props.loanissueobject.loan_number} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>GRACE PERIOD: {this.props.loanissueobject.grace_period} DAYS</h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Payment mode</th>
                            <th>Desc</th>
                            <th>Principle Paid</th>
                            <th>Interest Paid</th>
                            <th>Fine Paid</th>
                            <th>Total Paid</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>
                     
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.user==null?item.payment_by:item.user}</td>
                            <td>{item.paymentmethod}</td>

                            <td>{item.description}</td>
                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.fine_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPrinciplePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalFinePaid()} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalTotalPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        
                          </tr>

                      </tbody>
                  </reactstrp.Table>      

                                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>    

    </div>
    );
  }
}


class DailyReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }


  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                <br></br>

                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}>DAILY LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> LOAN NUMBER:  {this.props.loanissueobject.loan_number} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>GRACE PERIOD: {this.props.loanissueobject.grace_period} DAYS</h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

                  <br></br>
                  <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Principle Bf</th>
                          <th>Interest Bf</th>
                          <th>Principle Pay</th>
                          <th>Interest Pay</th>
                          <th>Total Pay</th>
                          <th>Principle Bal</th>
                          <th>Interest Bal</th>

                          <th>Received AMount</th>
                          <th>Receipt No</th>


                        </tr>
                      </thead>
                      <tbody>
                      {this.props.loandailyschedules.map(
                        (item)=>(
                          <tr>
                          <td>{item.repayment_number}</td>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.principlebefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.principlepay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          <td></td>

                          </tr>
                        ))}

                    </tbody>
                </reactstrp.Table>
                <br></br>
                <h3>Client Name:......................................................</h3>

                <h3>Officer:......................................................</h3>
                
                <h3>Manager:......................................................</h3>

                <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   
    </div>
    );
  }
}





class LoanFines_Print extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }


   //calculate total fine paid
   calculateTotalFinePaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.fine_paid)
      })

    return TotalPay;
   }


    //calcultae cumulative fine
    calculateTotalcumulativefine=()=>{
    var TotalPay=0
    this.props.accumulated_fines.map(
      (item)=>{
        TotalPay+=Number(item.accumalated_fine_total)
      })

    return TotalPay;
    }


  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> CUMULATIVE FINES REPORT </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> LOAN NUMBER:  {this.props.loanissueobject.loan_number} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>GRACE PERIOD: {this.props.loanissueobject.grace_period} DAYS</h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>


                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Cycle start date</th>
                            <th>Cycle due date</th>
                            <th>Interest unpaid</th>
                            <th>Principle unpaid</th>
                            <th>Total unpaid</th>
                            <th>Accumulated fine</th>
                            <th>End of grace period</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.props.accumulated_fines.map(
                          (item)=>(
                            <tr>
                            <td>{item.cycle_start_date}</td>
                            <td>{item.cycle_due_date}</td>
                            <td>{<CurrencyFormat value={item.interest_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.accumalated_fine_total.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.endofgraceperiod}</td>

                            </tr>
                          ))}
                          <tr>
                          <td>Total</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalcumulativefine().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                        
                          </tr>

                      </tbody>
                      </reactstrp.Table>

                                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>    

    </div>
    );
  }
}




class LoanIssuanceList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanissues: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,

    current_selected_loan:0,
    loan_particular_data_loading:false,

    loanschedule:[],
    loanissueobject:{},
    loanrepayments:[],
    loandailyschedules:[],
    companyprofile:{},
    loanfines:[],

    deletelist:[],
    totaltobepaidobject:{},
    interestpay:0,

    newreschdate:'',
    newreschamt:0,
    waiver_model_visible:false,
    
    
    schedule_edit_interest_value:0,
    schedule_edit_principe_value:0,
    schedule_edit_total_value:0,
    selected_scheduleobject:{},

    payment_model_visible:false,
    interest_paid:0,
    principle_paid:0,
    fine_paid:0,
    total_paid:0,
    interest_balance:0,
    principle_balance:0,
    total_balance:0,
    selected_paymentobject:{},

    accumulated_fines:[],
    fullypaid_loans: [],

  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

    }else{
       sacco= ''
       bizuserid= ''
       username=''
    }

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })


    axios.get(serverconfig.backendserverurl+`/customqueries/get_allloans/${sacco}`)
    .then(res => {
        this.setState({
          loanissues:JSON.parse(res.data.report)
        })

        this.setState({
          fullypaid_loans:JSON.parse(res.data.report_fullypaid)
        })

        this.setState({datarequested:false})
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({datarequested:false})

    })

}


  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.loanschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal;
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.loanschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal;
    }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay;
    }


      //calculate principle paid
      calculateTotalPrinciplePaid=()=>{
      var TotalPay=0
      this.state.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.principle_paid)
        })
  
      return TotalPay;
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay;
       }

             //calculate Total total paid
      calculateTotalTotalPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.total_paid)
          })
    
        return TotalPay;
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay;
       }


      //calculate total fine paid
      calculateTotaldailyFine=()=>{
        var TotalPay=0
        this.state.loanfines.map(
          (item)=>{
            TotalPay+=Number(item.cyclefine)
          })
    
        return TotalPay;
        }

        //calcultae cumulative fine
        calculateTotalcumulativefine=()=>{
          var TotalPay=0
          this.state.accumulated_fines.map(
            (item)=>{
              TotalPay+=Number(item.accumalated_fine_total)
            })
      
          return TotalPay;
          }


    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
      let form_data = new FormData();
      form_data.append('loanid', id);
      form_data.append('sacco',sacco);
      form_data.append('user',bizuserid);

      axios.post(serverconfig.backendserverurl+'/customqueries/deleteloan', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => window.location.reload(false),message.info('Success fully deleted Loan')
    )
    .catch(error => console.log(error))
   
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [
        {
          title: 'Loan number',
          dataIndex: 'loan_number',
          key: 'id',
          ...this.getColumnSearchProps('loan_number'),
          render:(text,record)=>
          <div>
          <h5> {record.loan_number}</h5>

          {
            Number(record.paidtotalprinciple)>=Number(record.approved_amount)?
            <p style={{color:"green"}}>Cleared</p>
            :
            <p style={{color:record.status==="active"?"green":'red'}}>( {record.status} )</p>
          }

          </div>
        },
        {
          title: 'Loan officer',
          dataIndex: 'loan_officername',
          key: 'id',
          ...this.getColumnSearchProps('loan_officername'),

        },

        {
          title: 'Loan type',
          dataIndex: 'loantypename',
          key: 'id',
          ...this.getColumnSearchProps('loantypename'),

        },

        {
          title: 'Issuance Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Maturity Date',
          dataIndex: 'maturity_date',
          key: 'id',
          ...this.getColumnSearchProps('maturity_date'),
        },

        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Loan Amount',
          dataIndex: 'approved_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Cycles defaulted',
          dataIndex: 'cycles_due',
          key: 'id',
        },
        {
          title: 'Principle due',
          dataIndex: 'principle_due',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Interest due',
          dataIndex: 'intrest_due',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {

          title: 'Principle paid',
          dataIndex: 'paidtotalprinciple',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Interest paid',
          dataIndex: 'paidtotalinterest',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Fine paid',
          dataIndex: 'finepaid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Loan Period',
          dataIndex: 'loan_period',
          key: 'id',
          render:(text,record)=><h5>{text} ({record.loan_primary_schedule})</h5>
        },


        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to view details(Schedule & Statement)</p>} title="View">
           <Link to={`/loanissues/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete loan</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
          </Popconfirm>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          {
              this.state.userprofile.branch_manager?
              <Popover content={<p>click here to deactivate paid off loan </p>} title="Deactivate">
                <Popconfirm title="Confirm Pay Off?" onConfirm={() => {
                      this.setState({datarequested:true})

                      let form_data = new FormData();
                      form_data.append('issued_loan_id',record.id);
          
                      //get the user profile here
                      axios.post(serverconfig.backendserverurl+'/customqueries/deactivateactiveloan',form_data,{
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                        })
                      .then(res => {
                          this.setState({datarequested:false})
                          console.log(res.data)
                          window.location.reload(false)
                      })
                }}>
                  <CloseCircleOutlined style={{color:'blue'}} />
                </Popconfirm>
   
             </Popover>
          :
          null
          }

          </p>,
        }
  
      ];

  
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>       
        </div>
      )

    }else{
      return(
        <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            loanschedule={this.state.loanschedule} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={el => (this.componentRef = el)} /> 
            </div>

            &nbsp;&nbsp;
            <div style={{display: "none"}}>
            <DailyReceiptToPrint
            loandailyschedules={this.state.loandailyschedules} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={el => (this.componentRefthree = el)} /> 
            </div>


            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <StatementToPrint
            loanrepayments={this.state.loanrepayments} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={sel => (this.componentReftwo = sel)} /> 
            </div>

            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <LoanFines_Print
            accumulated_fines={this.state.accumulated_fines} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={el => (this.componentRef_fines = el)} /> 
            </div>


            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Active loans" key="001" >
                <Table   
            columns={columns} 
            size='small'

            expandable={{
              expandedRowRender: (record) =>{
                if (Number(record.id)===Number(this.state.current_selected_loan)){
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                     >

                      {
                        this.state.loan_particular_data_loading===true?
                        <div style={{display:'flex',margin:20}}>
                        <Spin indicator={antIcon} />
                        </div>
                        :

                     <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Loan Schedule" key="02" >

                          <div style={{display:'flex',flexDirection:'row'}}>
                          <a style={{margin:10}}>
                           <ReactToPrint
                             trigger={() =>                     
                            <div style={{}}>
                              <Tooltip title="Click to print" placement="top">
                              <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                              <h6 style={{alignSelf:'center'}}>Print </h6>
                              </Tooltip>
                            </div>                          
                              }
                              content={() => this.componentRef}
                            />  
                          </a>


                          <Button type='primary'
                          icon={<MailFilled/>}
                          
                          onClick={
                            ()=>{
                              let form_data = new FormData();
                              form_data.append('loanid', this.state.loanissueobject.id);
                              form_data.append('sacco', sacco);
                              
                              console.log(this.state.entrant)
                                
                              this.setState({datarequested:true})
                      
                              //Now submit sale data to database
                              axios.post(serverconfig.backendserverurl+'/customqueries/email_loanschedule', form_data,{
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                              .then(res =>{
                                this.setState({datarequested:false})
                                message.info(res.data.message)
                              } 
                              )
                              .catch(error => console.log(error))   
                              
                            }
                          }
                          
                          style={{margin:10}}
                          >
                            Send via eMail

                          </Button>

                          <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>} 
                                                    style={{margin:10}}

                          >Download Excel report</Button>}>
                            <ExcelSheet data={this.state.loanschedule} name={"LOAN SCHEDULE"}>
                                <ExcelColumn label="#" value="repayment_number"/>
                                <ExcelColumn label="Date" value="date"/>
                                <ExcelColumn label="Principle Bf" value="principlebroughtforward"/>
                                <ExcelColumn label="Principle Pay" value="principle"/>
                                <ExcelColumn label="Interest Pay" value="interest"/>
                                <ExcelColumn label="Total Pay" value="total"/>
                                <ExcelColumn label="Principle Bal" value="balance"/>

                            </ExcelSheet>
                        </ExcelFile>

                        

                          </div>


                          <br></br>


                            
                          <reactstrp.Table bordered>
                          <thead>

                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Principle Bf</th>
                              <th>Principle Pay</th>
                              <th>Interest Pay</th>
                              <th>Total Pay</th>
                              <th>Principle Bal</th>
                            
                              { 
                              this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                              <th>Action</th>
                              :
                              null
                              }

                            </tr>
                          </thead>
                          <tbody>
                          {this.state.loanschedule.map(
                            (item)=>(
                              <tr>
                              <td>{item.repayment_number}</td>
                              <td>{item.date}</td>
                              <td>{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            
                              { 
                              this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <td>
                            <Tooltip title="Click to edit interest (waive)" placement="top">
                              <EditOutlined style={{color:'blue'}}
                              onClick={()=>{

                                this.setState({schedule_edit_interest_value:item.interest})
                                this.setState({schedule_edit_principe_value:item.principle})

                                this.setState({selected_scheduleobject:item})
                                this.setState({waiver_model_visible:true})
                                
                              }}
                              />
                              </Tooltip>
                            </td>
                            :
                            null}

                            
                              </tr>
                            ))}
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td></td>
                            </tr>

                        </tbody>
                       </reactstrp.Table>
             
                        </TabPane>
      
      
                        <TabPane tab="Loan Statement" key="03" >
                            <div style={{display:'flex',}}>

                              
                              &nbsp;
                              &nbsp;
                              &nbsp;
                              &nbsp;
                              &nbsp;

                              <a>
                              <ReactToPrint
                                trigger={() =>                     
                                  <div style={{}}>
                                    <Tooltip title="Click to print" placement="top">
                                    <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                                    <h6 style={{alignSelf:'center'}}>Print </h6>
                                    </Tooltip>
                                  </div>                          
                                    }
                                    content={() => this.componentReftwo}
                                  />  
                             </a>


                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;

                          <Button type='primary'
                          icon={<MailFilled/>}
                          
                          onClick={
                            ()=>{
                              let form_data = new FormData();
                              form_data.append('loanid', this.state.loanissueobject.id);
                              form_data.append('sacco', sacco);
                              
                              console.log(this.state.entrant)
                                
                              this.setState({datarequested:true})
                      
                              //Now submit sale data to database
                              axios.post(serverconfig.backendserverurl+'/customqueries/emailloanstatement', form_data,{
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                              .then(res =>{
                                this.setState({datarequested:false})
                                message.info(res.data.message)
                              } 
                              )
                              .catch(error => console.log(error))   
                              
                            }
                          }
                          
                          >
                            Send via eMail

                          </Button>
                          &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;

                          {
                              this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                              <Button  type="primary" htmlType='button'
                              onClick={()=>{
                                this.setState({datarequested:true})

                                let form_data = new FormData();
                                form_data.append('deletemode','loanrepayments');
                                form_data.append('deletelist',JSON.stringify(this.state.deletelist) );

                                axios.post(serverconfig.backendserverurl+'/customqueries/massdeleting', form_data, {
                                  headers: {
                                    'content-type': 'multipart/form-data'
                                  }
                                  })
                                .then(res => {
                                  //get new data back
                                  this.setState({datarequested:false})
                                  this.setState({deletelist:[]})
                                  window.location.reload(false)

                                })
                                .catch(error => console.log(error))

                              }}

                              >Delete payments</Button>
                              :
                              null
                              }



                              </div>


                        <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Payment mode</th>
                            <th>Desc</th>
                            <th>Principle Paid</th>
                            <th>Interest Paid</th>
                            <th>Fine Paid</th>
                            <th>Total Paid</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>


                            {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>Select Payment</th>
                            :
                            null
                           }

                          { 
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>Edit Payment</th>
                            :
                            null
                           }

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.user==null?item.payment_by:item.user}</td>
                            <td>{item.paymentmethod}</td>

                            <td>{item.description}</td>
                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.fine_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                           
                          

                            {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>
                              
                            <PlusOutlined  style={{color:this.state.deletelist.indexOf(item.id)> -1?'red':'green'}}  onClick={()=>{

                              if(this.state.deletelist.indexOf(item.id)> -1){
                                console.log("trans removed")
                                const newList = this.state.deletelist.filter((trans) => trans!== item.id);
                                this.setState({deletelist:newList});
                              }else{

                                this.setState({
                                  deletelist: [...this.state.deletelist, item.id]
                                  });

                                  console.log("trans added")
                              }
                                
                            }}/>

                            </th>
                            :
                            null
                          }


                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>
                           <Tooltip title="Click to edit payment entry" placement="top">
                            <EditOutlined style={{color:'blue'}}
                            onClick={()=>{

                              this.setState({interest_paid:item.interest_paid})
                              this.setState({principle_paid:item.principle_paid})
                              this.setState({fine_paid:item.fine_paid})
                              this.setState({interest_balance:item.interest_balance})
                              this.setState({total_balance:item.loan_balance})
                              this.setState({principle_balance:item.principle_balance})

                              this.setState({selected_paymentobject:item})
                              this.setState({payment_model_visible:true})
                              
                            }}
                            />
                            </Tooltip>
                           

                            </th>
                            :
                            null
                          }
                           
                           
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPrinciplePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalFinePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalTotalPaid()} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td></td>
                          <td></td>
                          <td></td>
                       
                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <td></td>
                            :
                            null
                          }

                          </tr>

                      </tbody>
                      </reactstrp.Table>

                        </TabPane>

                        <TabPane tab="Repayment" key="04" >
                         <LoanRepaymentDirectViewList loan_id={record.id} />

                        </TabPane>

                        <TabPane tab="Loan accumulated fines" key="01" >

                        <div style={{display:'flex',}}>
                        <a>
                        <ReactToPrint
                          trigger={() =>                     
                            <div style={{}}>
                              <Tooltip title="Click to print" placement="top">
                              <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                              <h6 style={{alignSelf:'center'}}>Print report </h6>
                              </Tooltip>
                            </div>                          
                              }
                              content={() => this.componentRef_fines}
                            />  
                        </a>

                          </div>
                          <br></br>
                          <br></br>



                        <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Cycle start date</th>
                            <th>Cycle due date</th>
                            <th>Interest unpaid</th>
                            <th>Principle unpaid</th>
                            <th>Total unpaid</th>
                            <th>Accumulated fine</th>
                            <th>End of grace period</th>
                            <th>No of days</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.accumulated_fines.map(
                          (item)=>(
                            <tr>
                            <td>{item.cycle_start_date}</td>
                            <td>{item.cycle_due_date}</td>
                            <td>{<CurrencyFormat value={item.interest_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.accumalated_fine_total.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.endofgraceperiod}</td>
                            <td>{item.no_ofdays}</td>

                            </tr>
                          ))}
                          <tr>
                          <td>Total</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalcumulativefine().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                        
                          </tr>

                      </tbody>
                      </reactstrp.Table>

                      </TabPane>
      
                      </Tabs>

                      }

                    </div>
                    );
                }else{
                  return null;
                }
              } ,
              rowExpandable: (record) => record.id !== this.state.current_selected_loan.id,
              onExpand:(condition,record)=>{
                this.setState({loan_particular_data_loading:true})

                //set curent id
                this.setState({current_selected_loan:record.id})

                var loanissueID=record.id


                //get other data
                axios.get(`${serverconfig.backendserverurl}/api/loanissues/${loanissueID}`)
                .then(res => {  
                    this.setState({loanissueobject: res.data})
            
                    this.setState({newreschdate:res.data.date})
                    this.setState({newreschamt:res.data.approved_amount})
            
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loanschedule: res.data})
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loanrepayments: res.data})
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loandailyschedules: res.data})

                    this.setState({loan_particular_data_loading:false})
                })

                axios.get(serverconfig.backendserverurl+`/customqueries/getdailyloanfines/${loanissueID}/${sacco}`,{
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                  })
                .then(res =>{
                  console.log(res.data)
                  this.setState({loanfines:JSON.parse(res.data.fines)})
                } 
                )
                .catch(error => console.log(error)) 

                //get acculated fines
                axios.get(serverconfig.backendserverurl+`/customqueries/getloanfines/${loanissueID}`,{
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                  })
                .then(res =>{
                  console.log(res.data)
                  this.setState({accumulated_fines:JSON.parse(res.data.report)})
                } 
                )
                .catch(error => console.log(error)) 


              },
            }}


            scroll={{ x: 1000 }}
            dataSource={this.state.loanissues} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br/>
            <h3>Total: {this.state.loanissues.length}</h3>
            <br />
            {
              this.state.userprofile.branch_manager?
              <div>
              <h3 style={{fontWeight:'bolder'}}>Import Loans here</h3>
              <LoanImportForm />
              </div>
              :
              null
            }
                
      
                </TabPane>

                <TabPane tab="Cleared loans" key="002" >
                <Table   
            columns={columns} 
            size='small'

            expandable={{
              expandedRowRender: (record) =>{
                if (Number(record.id)===Number(this.state.current_selected_loan)){
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                     >

                      {
                        this.state.loan_particular_data_loading===true?
                        <div style={{display:'flex',margin:20}}>
                        <Spin indicator={antIcon} />
                        </div>
                        :

                     <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Loan Schedule" key="02" >

                          <div style={{display:'flex',flexDirection:'row'}}>
                          <a style={{margin:10}}>
                           <ReactToPrint
                             trigger={() =>                     
                            <div style={{}}>
                              <Tooltip title="Click to print" placement="top">
                              <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                              <h6 style={{alignSelf:'center'}}>Print </h6>
                              </Tooltip>
                            </div>                          
                              }
                              content={() => this.componentRef}
                            />  
                          </a>


                          <Button type='primary'
                          icon={<MailFilled/>}
                          
                          onClick={
                            ()=>{
                              let form_data = new FormData();
                              form_data.append('loanid', this.state.loanissueobject.id);
                              form_data.append('sacco', sacco);
                              
                              console.log(this.state.entrant)
                                
                              this.setState({datarequested:true})
                      
                              //Now submit sale data to database
                              axios.post(serverconfig.backendserverurl+'/customqueries/email_loanschedule', form_data,{
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                              .then(res =>{
                                this.setState({datarequested:false})
                                message.info(res.data.message)
                              } 
                              )
                              .catch(error => console.log(error))   
                              
                            }
                          }
                          
                          style={{margin:10}}
                          >
                            Send via eMail

                          </Button>

                          <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>} 
                                                    style={{margin:10}}

                          >Download Excel report</Button>}>
                            <ExcelSheet data={this.state.loanschedule} name={"LOAN SCHEDULE"}>
                                <ExcelColumn label="#" value="repayment_number"/>
                                <ExcelColumn label="Date" value="date"/>
                                <ExcelColumn label="Principle Bf" value="principlebroughtforward"/>
                                <ExcelColumn label="Principle Pay" value="principle"/>
                                <ExcelColumn label="Interest Pay" value="interest"/>
                                <ExcelColumn label="Total Pay" value="total"/>
                                <ExcelColumn label="Principle Bal" value="balance"/>

                            </ExcelSheet>
                        </ExcelFile>

                          </div>


                          <br></br>


                            
                          <reactstrp.Table bordered>
                          <thead>

                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Principle Bf</th>
                              <th>Principle Pay</th>
                              <th>Interest Pay</th>
                              <th>Total Pay</th>
                              <th>Principle Bal</th>
                            
                              { 
                              this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                              <th>Action</th>
                              :
                              null
                              }

                            </tr>
                          </thead>
                          <tbody>
                          {this.state.loanschedule.map(
                            (item)=>(
                              <tr>
                              <td>{item.repayment_number}</td>
                              <td>{item.date}</td>
                              <td>{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            
                              { 
                              this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <td>
                            <Tooltip title="Click to edit interest (waive)" placement="top">
                              <EditOutlined style={{color:'blue'}}
                              onClick={()=>{

                                this.setState({schedule_edit_interest_value:item.interest})
                                this.setState({schedule_edit_principe_value:item.principle})

                                this.setState({selected_scheduleobject:item})
                                this.setState({waiver_model_visible:true})
                                
                              }}
                              />
                              </Tooltip>
                            </td>
                            :
                            null}

                            
                              </tr>
                            ))}
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td></td>
                            </tr>

                        </tbody>
                       </reactstrp.Table>
             
                        </TabPane>
      
      
                        <TabPane tab="Loan Statement" key="03" >
                            <div style={{display:'flex',}}>

                              {
                              this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                              <Button  type="primary" htmlType='button'
                              onClick={()=>{
                                this.setState({datarequested:true})

                                let form_data = new FormData();
                                form_data.append('deletemode','loanrepayments');
                                form_data.append('deletelist',JSON.stringify(this.state.deletelist) );

                                axios.post(serverconfig.backendserverurl+'/customqueries/massdeleting', form_data, {
                                  headers: {
                                    'content-type': 'multipart/form-data'
                                  }
                                  })
                                .then(res => {
                                  //get new data back
                                  this.setState({datarequested:false})
                                  this.setState({deletelist:[]})
                                  window.location.reload(false)

                                })
                                .catch(error => console.log(error))

                              }}

                              >Batch Delete</Button>
                              :
                              null
                              }

                              &nbsp;
                              &nbsp;
                              &nbsp;
                              &nbsp;
                              &nbsp;

                              <a>
                              <ReactToPrint
                                trigger={() =>                     
                                  <div style={{}}>
                                    <Tooltip title="Click to print" placement="top">
                                    <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                                    <h6 style={{alignSelf:'center'}}>Print </h6>
                                    </Tooltip>
                                  </div>                          
                                    }
                                    content={() => this.componentReftwo}
                                  />  
                             </a>


                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;

                          <Button type='primary'
                          icon={<MailFilled/>}
                          
                          onClick={
                            ()=>{
                              let form_data = new FormData();
                              form_data.append('loanid', this.state.loanissueobject.id);
                              form_data.append('sacco', sacco);
                              
                              console.log(this.state.entrant)
                                
                              this.setState({datarequested:true})
                      
                              //Now submit sale data to database
                              axios.post(serverconfig.backendserverurl+'/customqueries/emailloanstatement', form_data,{
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                              .then(res =>{
                                this.setState({datarequested:false})
                                message.info(res.data.message)
                              } 
                              )
                              .catch(error => console.log(error))   
                              
                            }
                          }
                          
                          >
                            Send via eMail

                          </Button>


                              </div>


                        <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Desc</th>
                            <th>Principle Paid</th>
                            <th>Interest Paid</th>
                            <th>Fine Paid</th>
                            <th>Total Paid</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>


                            {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>Select Payment</th>
                            :
                            null
                           }

                          { 
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>Edit Payment</th>
                            :
                            null
                           }

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.user==null?item.payment_by:item.user}</td>
                            <td>{item.description}</td>
                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.fine_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                           
                          

                            {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>
                              
                            <PlusOutlined  style={{color:this.state.deletelist.indexOf(item.id)> -1?'red':'green'}}  onClick={()=>{

                              if(this.state.deletelist.indexOf(item.id)> -1){
                                console.log("trans removed")
                                const newList = this.state.deletelist.filter((trans) => trans!== item.id);
                                this.setState({deletelist:newList});
                              }else{

                                this.setState({
                                  deletelist: [...this.state.deletelist, item.id]
                                  });

                                  console.log("trans added")
                              }
                                
                            }}/>

                            </th>
                            :
                            null
                          }


                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>
                           <Tooltip title="Click to edit payment entry" placement="top">
                            <EditOutlined style={{color:'blue'}}
                            onClick={()=>{

                              this.setState({interest_paid:item.interest_paid})
                              this.setState({principle_paid:item.principle_paid})
                              this.setState({fine_paid:item.fine_paid})
                              this.setState({interest_balance:item.interest_balance})
                              this.setState({total_balance:item.loan_balance})
                              this.setState({principle_balance:item.principle_balance})

                              this.setState({selected_paymentobject:item})
                              this.setState({payment_model_visible:true})
                              
                            }}
                            />
                            </Tooltip>
                           

                            </th>
                            :
                            null
                          }
                           
                           
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPrinciplePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalFinePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalTotalPaid()} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td></td>
                          <td></td>
                          <td></td>
                       
                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <td></td>
                            :
                            null
                          }

                          </tr>

                      </tbody>
                      </reactstrp.Table>

                        </TabPane>

                        <TabPane tab="Repayment" key="04" >
                         <LoanRepaymentDirectViewList loan_id={record.id} />

                        </TabPane>

                        <TabPane tab="Loan accumulated fines" key="01" >
                        <div style={{display:'flex',}}>
                        <a>
                        <ReactToPrint
                          trigger={() =>                     
                            <div style={{}}>
                              <Tooltip title="Click to print" placement="top">
                              <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                              <h6 style={{alignSelf:'center'}}>Print report </h6>
                              </Tooltip>
                            </div>                          
                              }
                              content={() => this.componentRef_fines}
                            />  
                        </a>

                          </div>
                          <br></br>
                          <br></br>
                        <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Cycle start date</th>
                            <th>Cycle due date</th>
                            <th>Interest unpaid</th>
                            <th>Principle unpaid</th>
                            <th>Total unpaid</th>
                            <th>Accumulated fine</th>
                            <th>End of grace period</th>
                            <th>No of days</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.accumulated_fines.map(
                          (item)=>(
                            <tr>
                            <td>{item.cycle_start_date}</td>
                            <td>{item.cycle_due_date}</td>
                            <td>{<CurrencyFormat value={item.interest_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.accumalated_fine_total.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.endofgraceperiod}</td>
                            <td>{item.no_ofdays}</td>

                            </tr>
                          ))}
                          <tr>
                          <td>Total</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalcumulativefine().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                        
                          </tr>

                      </tbody>
                      </reactstrp.Table>
                      <br></br>
                      <br></br>

                      </TabPane>
      
                      </Tabs>

                      }

                    </div>
                    );
                }else{
                  return null;
                }
              } ,
              rowExpandable: (record) => record.id !== this.state.current_selected_loan.id,
              onExpand:(condition,record)=>{
                this.setState({loan_particular_data_loading:true})

                //set curent id
                this.setState({current_selected_loan:record.id})

                var loanissueID=record.id


                //get other data
                axios.get(`${serverconfig.backendserverurl}/api/loanissues/${loanissueID}`)
                .then(res => {  
                    this.setState({loanissueobject: res.data})
            
                    this.setState({newreschdate:res.data.date})
                    this.setState({newreschamt:res.data.approved_amount})
            
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loanschedule: res.data})
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loanrepayments: res.data})
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loandailyschedules: res.data})

                    this.setState({loan_particular_data_loading:false})
                })

                axios.get(serverconfig.backendserverurl+`/customqueries/getdailyloanfines/${loanissueID}/${sacco}`,{
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                  })
                .then(res =>{
                  console.log(res.data)
                  this.setState({loanfines:JSON.parse(res.data.fines)})
                } 
                )
                .catch(error => console.log(error)) 

                //get acculated fines
                axios.get(serverconfig.backendserverurl+`/customqueries/getloanfines/${loanissueID}`,{
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                  })
                .then(res =>{
                  console.log(res.data)
                  this.setState({accumulated_fines:JSON.parse(res.data.report)})
                } 
                )
                .catch(error => console.log(error)) 


              },
            }}


            scroll={{ x: 1000 }}
            dataSource={this.state.fullypaid_loans} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br/>
            <h3>Total: {this.state.fullypaid_loans.length}</h3>
          
              
                </TabPane>

            </Tabs>



            <Modal              
              visible={this.state.waiver_model_visible}
              title={"Schedule edit form"}
              onCancel={()=>{this.setState({waiver_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({waiver_model_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                        let form_data = new FormData();
                        form_data.append('schedule_id', this.state.selected_scheduleobject.id);
                        form_data.append('principle', this.state.schedule_edit_principe_value);
                        form_data.append('interest', this.state.schedule_edit_interest_value);
                        form_data.append('total', Number(this.state.schedule_edit_principe_value)+Number(this.state.schedule_edit_interest_value));

                        axios.post(serverconfig.backendserverurl+`/customqueries/schedule_editing`, form_data, {
                          headers: {
                            'content-type': 'multipart/form-data'
                          }
                        })
                      .then(res => {
                        if(res.data.message=="success"){
                          message.info(res.data.message)
                          this.setState({datarequested:false})
                          window.location.reload(false)
                    
                        }else{
                          message.info("Some thing went wrong")
                        }
                    
                      } )
                      .catch(error => console.log(error))
                      this.setState({waiver_model_visible:false})

                    }}>
                  Update
                </Button>
                ]}
                >
                  
                <FormItem label="New Interest value" >
                    <Input name="schedule_edit_interest_value"  placeholder="New Interest value" value={this.state.schedule_edit_interest_value} onChange={(val)=>{this.setState({schedule_edit_interest_value:val.target.value})}} />
                </FormItem>             

                <FormItem label="New principle value" >
                    <Input name="schedule_edit_principe_value"  placeholder="New Principle value" value={this.state.schedule_edit_principe_value} onChange={(val)=>{this.setState({schedule_edit_principe_value:val.target.value})}} />
                </FormItem>      
        
               </Modal> 


               <Modal              
              visible={this.state.payment_model_visible}
              title={"Loan payment edit form"}
              onCancel={()=>{this.setState({payment_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({payment_model_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{

                        let form_data = new FormData();
                        form_data.append('payment_id', this.state.selected_paymentobject.id);
                        form_data.append('interest_paid', this.state.interest_paid);
                        form_data.append('principle_paid', this.state.principle_paid);
                        form_data.append('fine_paid', this.state.fine_paid);
                        form_data.append('username', username);
                        
                        axios.post(serverconfig.backendserverurl+`/customqueries/loanpayment_editing`, form_data, {
                          headers: {
                            'content-type': 'multipart/form-data'
                          }
                        })
                      .then(res => {
                        if(res.data.message=="success"){
                          message.info(res.data.message)
                          this.setState({datarequested:false})
                          window.location.reload(false)
                    
                        }else{
                          message.info("Some thing went wrong")
                        }
                    
                      } )
                      .catch(error => console.log(error))
                      this.setState({payment_model_visible:false})

                    }}>
                  Update
                </Button>
                ]}
                >

               <FormItem label="Interest paid" >
                  <Input name="interest_paid"  placeholder="Interest paid" value={this.state.interest_paid} onChange={(val)=>{this.setState({interest_paid:val.target.value})}} />
                </FormItem>             

                <FormItem label="Principle paid" >
                  <Input name="principle_paid"  placeholder="Principle paid" value={this.state.principle_paid} onChange={(val)=>{this.setState({principle_paid:val.target.value})}} />
                </FormItem>      
        
                <FormItem label="Fine paid" >
                    <Input name="fine_paid"  placeholder="Fine paid" value={this.state.fine_paid} onChange={(val)=>{this.setState({fine_paid:val.target.value})}} />
                </FormItem>   

               </Modal>
        </div>  )

    }
    
  }
}

export default LoanIssuanceList; 
