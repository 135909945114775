import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input,Switch, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import { array, number } from 'prop-types';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

 

  }

  render() {
    return (
      <div style={{padding:20}}>
            
            <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
                
                  </Col>
            </Row>          
          <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOAN REPAYMENT RECEIPT </h3>

         <reactstrp.Table bordered>
          <thead>
          <tr>
            <th>Account Name</th>
            <th>{this.props.selectedloanobject.accountName}</th>
          </tr>

          <tr>
          <th>Account Number</th>
          <th>{this.props.selectedloanobject.accountNumber}</th>

          </tr>

          <tr>
          <th>Issuance Date</th>
          <th>{this.props.selectedloanobject.date}</th>

          </tr>

          <tr>
          <th>Loan Period</th>
          <th>{this.props.selectedloanobject.loan_period}</th>

          </tr>

          <tr>
          <th>Loan Interest Rate</th>
          <th>{this.props.selectedloanobject.interest_rate}</th>
          </tr>

          <tr>
          <th>Loan Amount</th>
          <th>
          <CurrencyFormat value={this.props.selectedloanobject.approved_amount} displayType={'text'} thousandSeparator={true}  />
          </th>
          </tr>

          <tr>
          <th>Total Interest to be Paid</th>
          <th>
          <CurrencyFormat value={this.props.totalinteresttobepaid} displayType={'text'} thousandSeparator={true}  />

          </th>
          </tr>

          <tr>
          <th>Total Interest Paid</th>
          <th>
          <CurrencyFormat value={this.props.totalinterestpaid} displayType={'text'} thousandSeparator={true}  />

          </th>

          </tr>

          <tr>
          <th>Total Principle Paid</th>
          <th>
          <CurrencyFormat value={this.props.totalprinciplepaid} displayType={'text'} thousandSeparator={true}  />

          </th>
          </tr>


          </thead>
                       
          </reactstrp.Table>     
         
    </div>
    );
  }
}



class LoanRepaymentDirectViewList extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    slipnumber:'',
    paidinby:'',
    transactorcontact:'',
    availableloans:[],
    datasubmittedsuccessfully:false,
    lastslipnumber:0,
    selectedloan:'',
    loanschedules:[],
    principleamount:0,
    fine:0,
    debitaccount:'',
    disabledamount:false,
    selectedloanobject:{},

    totalprincipletobepaid:0,
    totalinteresttobepaid:0,
    totalprinciplepaid:0,
    totalinterestpaid:0,

    interestamount:0,
    loanaccountcreditingmethod:'directcashcrediting',
    companyaccounts: [],
    debited_account:'',
    creditloanaccount:'no',

    earlypayoff:false,
    intrest_due:0,
    no_dueschedules:0,
    remaining_schedule_totals_interest:0


  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    var loan_id=this.props.loan_id

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      username=''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })
       // this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })


    //loan loan details
    this.handleselectedloanChange(loan_id)


  }


  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  handleselectedaccountChange= (e) =>{
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
      })

      axios.get(`${serverconfig.backendserverurl}/api/loanissues/?account=${e}`)
      .then(res => {  
          this.setState({availableloans: res.data})
          console.log(res.data)
      })

      this.setState({slipnumber:Number(this.state.lastslipnumber)+1})

  }


  //handle client change
  handleselectedloanChange= (e) =>{
    this.setState({selectedloan:e});

    axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${e}`)
    .then(res => {  
        this.setState({loanschedules: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanissues/${e}`)
    .then(res => {  
        this.setState({selectedloanobject: res.data})

        //set account
        this.handleselectedaccountChange(res.data.account)
    })


    //get all payments now
    axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${e}`)
    .then(res => {  
      const totalptobe = res.data.reduce((Total, item) => Total + item.principle, 0);
      const totalitobe = res.data.reduce((Total, item) => Total + item.interest, 0);

      this.setState({totalprincipletobepaid:totalptobe})   
      this.setState({totalinteresttobepaid:totalitobe})   

    })
    
    
    axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${e}`)
    .then(res => {  

      const totalppaid = res.data.reduce((Total, item) => Total + item.principle_paid, 0);
      const totalipaid = res.data.reduce((Total, item) => Total + item.interest_paid, 0);

      this.setState({totalprinciplepaid:totalppaid})   
      this.setState({totalinterestpaid:totalipaid})  

    })


    axios.get(`${serverconfig.backendserverurl}/customqueries/getloan_interestdue/${e}`)
    .then(res => {  
        this.setState({intrest_due: res.data.intrest_due})
        this.setState({no_dueschedules:res.data.no_dueschedules})
        this.setState({remaining_schedule_totals_interest:res.data.remaining_schedule_totals_interest})

     })


  }


  handleprincipleamountChange= (e) => this.setState({ principleamount: e.target.value});
  handlefineChange= (e) => this.setState({ fine: e.target.value});


  //submit the deposit now
  handleFormSubmit=(event) =>{

     if(this.state.debitaccount=="yes" && this.state.loanaccountcreditingmethod==="savingstransfercredit"  && Number(this.state.selectedaccountobject.UserBalance)<(Number(this.state.principleamount)+Number(this.state.interestamount)+Number(this.state.fine))){
        message.info("Less Account balance")
     }else{

      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('principleamount', this.state.principleamount);
      form_data.append('interestamount', this.state.interestamount);
      form_data.append('totalloanamount', Number(this.state.principleamount)+Number(this.state.interestamount));

      form_data.append('debitaccount', this.state.debitaccount);
      form_data.append('fine', this.state.fine);
      form_data.append('sacco', sacco);
      form_data.append('username', username);
      form_data.append('loanid', this.state.selectedloan);
      form_data.append('date', this.state.date);

      form_data.append('loanaccountcreditingmethod', this.state.loanaccountcreditingmethod);
      form_data.append('debited_account', this.state.debited_account);
      form_data.append('earlypayoff', this.state.earlypayoff);

      axios.post(serverconfig.backendserverurl+'/customqueries/directloanrepayment', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)

        this.setState({principleamount:0})
        this.setState({interestamount:0})
        this.setState({fine:0})


        //get all payments now
        axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${this.state.selectedloan}`)
        .then(res => {  
          const totalptobe = res.data.reduce((Total, item) => Total + item.principle, 0);
          const totalitobe = res.data.reduce((Total, item) => Total + item.interest, 0);

          this.setState({totalprincipletobepaid:totalptobe})   
          this.setState({totalinteresttobepaid:totalitobe})   

        })
        
        
        axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${this.state.selectedloan}`)
        .then(res => {  

          const totalppaid = res.data.reduce((Total, item) => Total + item.principle_paid, 0);
          const totalipaid = res.data.reduce((Total, item) => Total + item.interest_paid, 0);

          this.setState({totalprinciplepaid:totalppaid})   
          this.setState({totalinterestpaid:totalipaid})  


        })


        this.setState({datasubmittedsuccessfully:true})
        
      })
      .catch(error => console.log(error))
     }

  }

  //calculate percetabge of ramaining schedule interest
  cal_percenatageoffremaingschedules_int=()=>{
    var total=0
    var percentage=Number(this.state.selectedloanobject.percentageof_remainginginterest_on_earlypayoff) 
    var pctg=(percentage/100)*Number(this.state.remaining_schedule_totals_interest)
    total=pctg
    return total;
  }

    //calculate percetabge of ramaining schedule interest
    calculatepercentageoff_fisrtinstall_interest_incaseofno_intdue=()=>{
      var total=0
      var percentage=Number(this.state.selectedloanobject.percentageonfirstinst_interest_incase_no_dueinterest_onloanpayoff) 
      
      if (this.state.loanschedules.length>0){
        var pctg=(percentage/100)*Number(this.state.loanschedules[0].interest)
        total=pctg
      }
      
      return total;
  
    }


  //calcultae interst payoff
  calculateInterstPayoff=()=>{
    var total=0
    var intr_due=Number(this.state.intrest_due)

    if(Number(intr_due)>0){
      total+=intr_due
      total+=this.cal_percenatageoffremaingschedules_int()
    }else{
      total+=this.calculatepercentageoff_fisrtinstall_interest_incaseofno_intdue()
      total+=this.cal_percenatageoffremaingschedules_int()
    }

    return total;
  }

  



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin> 
         </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none",}}>
               &nbsp;&nbsp;
              <ReceiptToPrint
              date={this.state.date} 
              selectedloanobject={this.state.selectedloanobject} 
              ref={el => (this.componentRef = el)}
              
              totalinterestpaid={this.state.totalinterestpaid} 
              totalprinciplepaid={this.state.totalprinciplepaid} 
              totalinteresttobepaid={this.state.totalinteresttobepaid} 
              totalprincipletobepaid={this.state.totalprincipletobepaid} 
              /> 
            </div>


            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Made Loan Repayment "
                    subTitle="Please finish off transaction"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                      <ReactToPrint
                      trigger={() => 
                      <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                      Print
                      </Button>
                      }
                      content={() => this.componentRef}
                      />
                    ]}
                    />
                    </Card>
                  </Col>
            </Row>
          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="12">
                    <Card>
                    <h3 style={{fontWeight:'bolder',color:'#154360'}}>Member Loan Repayment</h3>

                    <FormItem label=" Select Member Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                             
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    
                    <FormItem label="Select Loan to Repay">
                        <Select 
                        placeholder="Select Loan to Repay" 
                        style={{  }} 
                        value={this.state.selectedloan}
                        onChange={this.handleselectedloanChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                              
                        >
                          {this.state.availableloans.map(
                            (loan)=>(
                              <Option value={loan.id}>Amount {loan.approved_amount} | Date {loan.date} | Months {loan.loan_period}</Option>
                            ))}
                        </Select>
                      </FormItem>
                      <br></br>

                    <div style={{display:'flex',flexDirection:'row'}}>
                    <div style={{display:'flex',flexDirection:'column',margin:10}}>
                      <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                      <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                      <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                      <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                      <h4>Account Balance:<CurrencyFormat value={this.state.selectedaccountobject.UserBalance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Status:{this.state.selectedaccountobject.account_status}</h4>

                      </div>

                      <div style={{display:'flex',flexDirection:'column',margin:10,borderWidth:2,borderRadius:10,borderColor:'black'}}>
                      <h3 style={{fontWeight:'bold',color:'#154360'}}>Loan Details</h3>
                      <h4>Loan Amount:<CurrencyFormat value={this.state.totalprincipletobepaid} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Interest Amount To be Paid:<CurrencyFormat value={this.state.totalinteresttobepaid} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Principle Paid:<CurrencyFormat value={this.state.totalprinciplepaid} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Interest Paid:<CurrencyFormat value={this.state.totalinterestpaid} displayType={'text'} thousandSeparator={true}  /> </h4>
                      </div>

                    </div>

  
                    <br></br>

                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>LOAN REPAYMENT FORM</h3>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                    >

                    <FormItem label="Date"
                      name='date'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select date',
                        },
                      ]}
                    >
                        <DatePicker value={moment()} onChange={(date, dateString)=>{this.setState({ date: dateString})}} format={dateFormat} />
                    </FormItem>

                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Make early pay off the loan?</h4>}  >
                          <Switch
                              checked={this.state.earlypayoff}
                              onChange={val=>{
                                this.setState({earlypayoff:val})

                                //set principle or interest balanace
                                if (val===true){
                                  this.setState({principleamount:Number(this.state.totalprincipletobepaid)-Number(this.state.totalprinciplepaid)})
                                  this.setState({interestamount:this.calculateInterstPayoff()})

                                }else{
                                  this.setState({principleamount:0})
                                  this.setState({interestamount:0})
                                }
                            
                            }}
                              checkedChildren="True"
                              unCheckedChildren="False"
                              />
                      </FormItem>

                    {
                      this.state.earlypayoff===true?
                      <div style={{}}>
                      <Card>
                      <h4>Due cycles no:    {this.state.no_dueschedules} </h4>
                        <h4>Interest due: <CurrencyFormat value={this.state.intrest_due} displayType={'text'} thousandSeparator={true}  /></h4>
                        <h4>Remaining schedule interest total: <CurrencyFormat value={this.state.remaining_schedule_totals_interest} displayType={'text'} thousandSeparator={true}  /></h4>
                        <h4>Percentage total of remaining schedules interest after due interest : <CurrencyFormat value={this.cal_percenatageoffremaingschedules_int()} displayType={'text'} thousandSeparator={true}  /></h4>
                        
                        <h4>Percentage total off first installment if no due installment : <CurrencyFormat value={this.calculatepercentageoff_fisrtinstall_interest_incaseofno_intdue()} displayType={'text'} thousandSeparator={true}  /></h4>
                        <h4>Calculated pay interest  : <CurrencyFormat value={this.calculateInterstPayoff()} displayType={'text'} thousandSeparator={true}  /></h4>

                      </Card>
                      
                     </div>
                      :
                      null
                    }
                    <br></br>


                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Principle Amount:</h4>}>
                      <Input disabled={this.state.earlypayoff===true?true:false} type='number' placeholder="Amount" value={this.state.principleamount} onChange={(val)=>{this.setState({principleamount:val.target.value})}}/>
     
                    </FormItem>

                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Interest Amount:</h4>}>
                      <Input disabled={this.state.earlypayoff===true?true:false} type='number' placeholder="Amount" value={this.state.interestamount} onChange={(val)=>{this.setState({interestamount:val.target.value})}}/>
     
                    </FormItem>
  
                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Fine:</h4>}>
                      <Input type='number' placeholder="Amount" value={this.state.fine} onChange={this.handlefineChange}/>
                    </FormItem>


                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Credit Loan Account (Yes) or just loan clearance (No)?:</h4>}>
                        <Select 
                        placeholder="Debit Account?" 
                        style={{ }} 
                        value={this.state.debitaccount}
                        onChange={(val)=>{this.setState({debitaccount:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                     
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Select>
                      </FormItem>

                      {
                        this.state.debitaccount==="yes"?
                        <FormItem label={<h4 style={{fontWeight:'bold'}}>Chose Loan Account crediting method.</h4>}>
                        <Select 
                        placeholder="Loan crediting method" 
                        style={{  }} 
                        value={this.state.loanaccountcreditingmethod}
                        onChange={(val)=>{this.setState({loanaccountcreditingmethod:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                     
                        >
                          <option value="directcashcrediting">Direct Cash crediting</option>
                          <option value="savingstransfercredit">Savings Transfer credit</option>
                        </Select>
                      </FormItem>
                        :
                        null
                      }

                    {
                      this.state.loanaccountcreditingmethod==="directcashcrediting" && this.state.debitaccount==="yes"?
                      <FormItem label={<h4 style={{fontWeight:'bold'}}>Receiving A/C (Debit A/C).</h4>}>
                          <Select 
                          placeholder="Account" 
                          value={this.state.debited_account}
                          onChange={(val)=>{this.setState({debited_account:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>
                      :
                      null
                    }
                
                    <FormItem>
                      <Button  type="primary" htmlType="submit">Submit</Button>
                    </FormItem>
                  </Form>
  
                  </Card>
                  
                </Col>
  
            </Row>
  
            </div>
  
       </div>              
                      
        )
      }

    }
   
  }
}

export default LoanRepaymentDirectViewList; 
