import React from 'react';
import MemberForm from '../components/MemberForm'
import ActivateMemberForm from '../components/ActivateMemberForm'

import axios from 'axios'
import { Table,DatePicker,Form, Input,Tabs, Button,Collapse,Typography,Space,Divider,Popover,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,PrinterOutlined,DownloadOutlined,FundViewOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import ExpensePaymentForm from '../components/ExpensePaymentForm'
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import moment from 'moment';
import TrialBalanceReport from './TrialBalanceView'
import TrialBalanceSummarisedReport from './TrialBalanceSummarisedView'

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';
const monthformat = 'MM/YYYY';
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


  }

  render() {
    return (
      <div style={{padding:20}}>
        <Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
              
                </Col>
       </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>Trial Balance Report For {this.props.period}</h3>

           <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>ACCOUNT </th>
                          <th>DEBIT</th>
                          <th>CREDIT</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.trialist.map(
                        (item)=>(
                          <tr>
                          <td>{item.account}</td>
                          <td>{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <th style={{fontWeight:'bolder'}}>Total</th>
                        <th><CurrencyFormat value={this.props.totaldebit} displayType={'text'} thousandSeparator={true}/></th>
                        <th><CurrencyFormat value={this.props.totalcredit} displayType={'text'} thousandSeparator={true}/></th>
                        </tr>
                    </tbody>
                </reactstrp.Table>    

                                <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>          
      </div>
    );
  }
}


class TrialBalanceView_Combined extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    members: [],
    viewpaymentmodal:false,
    expensedetails:{},
    companyprofile:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    trialist:[],
    period:moment().format(dateFormat).toString()
  };
  

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
    this.setState({datarequested:false})

  }

  render() {
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin> 
        </div>
      )

    }else{
      return(
        <div>
        <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Trial balance" key="01" >
              <TrialBalanceReport />

            </TabPane>
            <TabPane tab="Summarised trial balance" key="02" >
              <TrialBalanceSummarisedReport />

            </TabPane>
        </Tabs>
            

          
        </div>
    )

    }


  }
}

export default TrialBalanceView_Combined; 
