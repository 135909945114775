import React from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});



var sacco= ''
var username=''
var token= ''

class UserSaccoAccountList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    accounts: [],
    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    can_deposit:false,
    can_withdraw:false,
    can_register_members:false,
    can_view_members:false,
    can_register_loans:false,
    can_assess_loans:false,
    can_approve_loans:false,
    can_issue_loans:false,
    can_enter_loan_repayment:false,
    can_enter_expenses:false,
    can_view_expenses:false,
    can_enter_incomes:false,
    can_view_incomes:false,
    can_enter_staff:false,
    can_view_staff:false,
    can_enter_assets:false,
    can_view_assets:false,
    viewtill:false,
    viewdaysheet:false,
    viewoutstandingloanreport:false,
    sendmessages:false,
    editaccounts:false,
    accountactivation:false,
    loandailyreport:false,
    outstandingloans:false,
    loanrepaymentstatus:false,
    loandefaultsreport:false,
    loanagingreport:false,
    loandisburshmentreport:false,
    viewissuedloans:false,
    viewdailyloanrepayments:false,
    searchloanrepayments:false,
    viewloanpaymentqueues:false, 

    receive_interbranchmoney:false, 
    send_interbranchmoney:false, 

    can_approve_loans_partially:false, 
    can_reverse_loan_process:false, 
    user_saccoaccounts:[]
  };

  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  


  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/user_saccoaccounts/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };



  componentDidMount(){
    var accountID=this.props.match.params.accountID;

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/user_saccoaccounts/?user=${accountID}`)
    .then(res => {
        this.setState({
          user_saccoaccounts:res.data
        })

        this.setState({datarequested:false})

    })

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      {
        title: 'Account code',
        dataIndex: 'account_code',
        key: 'id',
        ...this.getColumnSearchProps('account_code'),
      },
      {
        title: 'Account name',
        dataIndex: 'account_name',
        key: 'id',
        ...this.getColumnSearchProps('account_name'),
      },
     
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>

        </p>,
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL COMPANY ACCOUNT ATTACHMENTS" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.user_saccoaccounts} bordered/>
            </Panel>
            </Collapse>

        </div>
    )
    }
   
  }
}

export default UserSaccoAccountList; 
