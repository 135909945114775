import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })
  
    }

    calculateInterestdefaulted=()=>{
      var total=0
      this.props.loanissues.map((item)=>{
        total+=Number(item.interest_balance)
      })
      return total;
    }
   
    calculateprincipledefaulted=()=>{
      var total=0
      this.props.loanissues.map((item)=>{
        total+=Number(item.principle_balance)
      })
      return total;
    }


   
    render() {
      return (
        <div style={{padding:20}}>
           <style>
            {`@media print {
            @page { size: landscape; }
             }`}
          </style>
       <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
                
                  </Col>
       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOAN DEFAULTS REPORT AS OF {this.props.date} </h3>
            
            
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Loan number</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Contact</th>
                            <th>Issuance Date</th>
                            <th>Loan Amount</th>

                            <th>Due period</th>
                            <th>Due Principle</th>
                            <th>Due Interest</th>

                            <th>Principle in default</th>
                            <th>Interest in default</th>
                            <th>Total in default</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.client_no}</td>
                            <td>{item.loan_number}</td>
                            <td>{item.accountNumber}</td>
                            <td>{item.accountName}</td>
                            <td>{item.RegisteredPhoneNumber1}</td>

                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.approved_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.cycles_due}</td>

                            <td>{<CurrencyFormat value={item.principle_due} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.intrest_due} displayType={'text'} thousandSeparator={true}/>}</td>                            
                      
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={Number(item.interest_balance)+Number(item.principle_balance)} displayType={'text'} thousandSeparator={true}/>}</td>                            

                            </tr>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals ({this.props.loanissues.length})</td>
                          <td></td>
                          <td></td>

                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateprincipledefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateInterestdefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateInterestdefaulted()+this.calculateprincipledefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                          
                      </tbody>
                  </reactstrp.Table>   
           
                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   

        </div>
      );
    }
  }


class LoanDefaultsReport extends React.Component {

  state = {
    loanissues:[],
    dateone:moment().format(dateFormat).toString(),
    date:moment().format(dateFormat).toString(),  
    datarequested:true,
    totalsaggregate:{},
    staffs:[],
    creditofficer:'',
    datetwo:moment().format(dateFormat).toString(),  
   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('sacco', sacco);
    form_data.append('date', moment().format(dateFormat).toString());

    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getLoanDefaultsReport', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({loanissues:JSON.parse(res.data.loanissues)})
      this.setState({totalsaggregate:JSON.parse(res.data.loanissuesaggregate)})
    } 
    )
    .catch(error => console.log(error)) 

    axios.get(serverconfig.backendserverurl+`/api/staffs/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          staffs:res.data
        })
    })

  }
 

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  calculateInterestdefaulted=()=>{
    var total=0
    this.state.loanissues.map((item)=>{
      total+=Number(item.interest_balance)
    })
    return total;
  }
 
  calculateprincipledefaulted=()=>{
    var total=0
    this.state.loanissues.map((item)=>{
      total+=Number(item.principle_balance)
    })
    return total;
  }


   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {

       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>             </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none",}}>
               &nbsp;&nbsp;
              <ReportToPrint
              date={this.state.dateone} 
              loanissues={this.state.loanissues} 
              totalsaggregate={this.state.totalsaggregate} 
              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="LOAN DEFAULTS REPORT" key="1">



              <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}> 

<ReactToPrint
    trigger={() => 
    <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
    Print
    </Button>
    }
    content={() => this.componentRef}
    />

&nbsp;&nbsp;&nbsp;
<ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>}>Download report (Excel)</Button>}>
  <ExcelSheet data={this.state.loanissues} name="Loan defaults report">

      <ExcelColumn label="Loan number" value="loan_number"/>
      <ExcelColumn label="Account Name" value="accountName"/>
      <ExcelColumn label="Account Number" value="accountNumber"/>
      <ExcelColumn label="Issuance date" value="date"/>
      <ExcelColumn label="Loan Amount" value="approved_amount"/>
      
      <ExcelColumn label="Cycles Due" value="cycles_due"/>
      <ExcelColumn label="Interest Due" value="intrest_due"/>
      <ExcelColumn label="principle Due" value="principle_due"/>

      <ExcelColumn label="Principle in default " value="principle_balance"/>
      <ExcelColumn label="Interest in default " value="interest_balance"/>

  </ExcelSheet>
 </ExcelFile>
 &nbsp;&nbsp;&nbsp;


 <Form  layout="inline" >

<FormItem label="Default by (Date)">
<DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
</FormItem>

<FormItem label="Search by credit officer"
        name="creditofficer"
        rules={[
          {
            required: false,
            message: 'Please Select creditofficer',
          },
        ]}
      >
      <Select placeholder="Credit Officer"  value={this.state.creditofficer} onChange={(val)=>{this.setState({creditofficer:val})}} >
      <Option value={0}>{"All officers"}</Option>

        {this.state.staffs.map(
          (ctgry)=>(
            <Option value={ctgry.id}>{ctgry.full_Name}</Option>
          ))}
      </Select>
  </FormItem>

<FormItem>
<Button onClick={()=>{
    this.setState({loanissues:[]})
    this.setState({totalsaggregate:{}})


    let form_data = new FormData();
    form_data.append('sacco', sacco);
    form_data.append('creditofficer', this.state.creditofficer);
    form_data.append('date', this.state.date);

    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getLoanDefaultsReport_bycreditofficer', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({loanissues:JSON.parse(res.data.loanissues)})
      this.setState({totalsaggregate:JSON.parse(res.data.loanissuesaggregate)})
    } 
    )
    .catch(error => console.log(error)) 



}} type="primary" htmlType="button">Search</Button>
</FormItem> 

</Form>


 </div>

 <br/>
 <div>
 <Form  layout="inline" >
                <FormItem label="Search by credit officer and issuance period">
                  <Select placeholder="Credit Officer"  value={this.state.creditofficer} onChange={(val)=>{this.setState({creditofficer:val})}} >
                  <Option value={0}>{"All officers"}</Option>
                    {this.state.staffs.map(
                      (ctgry)=>(
                        <Option value={ctgry.id}>{ctgry.full_Name}</Option>
                      ))}
                  </Select>
              </FormItem>

              <FormItem label="Date Range (Issuance Period)">
                  <RangePicker defaultValue={moment()} onChange={(date, dateString) =>{
                      this.setState({ dateone: dateString[0]});
                      this.setState({ datetwo: dateString[1]});
                    }} format={dateFormat} />
              </FormItem>


            <FormItem>
            <Button onClick={()=>{
                this.setState({loanissues:[]})

                if(this.state.dateone==='' || this.state.datetwo===''){
                  alert("Date one or two missing")
                  }else{
                    this.setState({datarequested:true})

                let form_data = new FormData();
                form_data.append('sacco', sacco);
                form_data.append('creditofficer', this.state.creditofficer);
                form_data.append('dateone', this.state.dateone);
                form_data.append('datetwo', this.state.datetwo);
  
                //Now submit sale data to database
                axios.post(serverconfig.backendserverurl+'/customqueries/getLoanDefaultsReport_bycreditofficer_daterange', form_data,{
                headers: {
                  'content-type': 'multipart/form-data'
                }
                })
                .then(res =>{
                  this.setState({datarequested:false})
                  this.setState({loanissues:JSON.parse(res.data.loanissues)})
                  this.setState({totalsaggregate:JSON.parse(res.data.loanissuesaggregate)})
              
                  this.setState({loanissues_placeholder:JSON.parse(res.data.loanissues)})
                } 
                )
                .catch(error => console.log(error)) 
              }


            }} type="primary" htmlType="button">Search</Button>
            </FormItem> 

            </Form>
            </div>
            <br/>



 
              <div style={{overflowX:'auto'}}>
              <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Loan number</th>
                            <th>Account Number</th>
                            <th>Account Name</th>
                            <th>Contact</th>

                            <th>Issuance Date</th>
                            <th>Loan Amount</th>

                            <th>Due period</th>
                            <th>Due Principle</th>
                            <th>Due Interest</th>

                            <th>Principle in default</th>
                            <th>Interest in default</th>
                            <th>Total in default</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanissues.map(
                          (item)=>(
                            <tr>
                             <td>{item.client_no}</td>

                            <td>{item.loan_number}</td>
                            <td>{item.accountNumber}</td>
                            <td>{item.accountName}</td>
                            <td>{item.RegisteredPhoneNumber1}</td>

                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.approved_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.cycles_due}</td>

                            <td>{<CurrencyFormat value={item.principle_due} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.intrest_due} displayType={'text'} thousandSeparator={true}/>}</td>                            
                      
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>                            
                            <td>{<CurrencyFormat value={Number(item.interest_balance)+Number(item.principle_balance)} displayType={'text'} thousandSeparator={true}/>}</td>                            

                            </tr>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals ({this.state.loanissues.length})</td>
                          <td></td>
                          <td></td>

                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateprincipledefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateInterestdefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateInterestdefaulted()+this.calculateprincipledefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                          
                      </tbody>
                  </reactstrp.Table>      
              </div>
                   
              </Panel>
              </Collapse>
          </div>
      )}
   
   }
}

export default LoanDefaultsReport; 
