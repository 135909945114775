import React from 'react';
import { Form, Input, Button,Select,Spin,DatePicker,Tooltip,Result,message,Card,InputNumber,Table,Modal } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined,DownloadOutlined, LoadingOutlined,PlusOutlined,PrinterOutlined, MailOutlined,UploadOutlined,EditOutlined, } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import { number } from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''
var bizuserid= ''
var username=''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

//calculate principleTotal
calculateScheduleTotal=()=>{
  var principleTotal=0
  this.props.loanschedule.map(
    (item)=>{
      principleTotal+=Number(item.principle)
    })

  return principleTotal;
}

  //calculate principleTotal
  calculateInterestTotal=()=>{
    var InterestTotal=0
    this.props.loanschedule.map(
      (item)=>{
        InterestTotal+=Number(item.interest)
      })

    return InterestTotal;
  }

  //calculate principleTotal
  calculateTotalPay=()=>{
    var TotalPay=0
    this.props.loanschedule.map(
      (item)=>{
        TotalPay+=Number(item.total)
      })

    return TotalPay;
  }



  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> LOAN NUMBER:  {this.props.loanissueobject.loan_number} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>GRACE PERIOD: {this.props.loanissueobject.grace_period} DAYS</h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

           <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Principle Bf</th>
                            <th>Principle Pay</th>
                            <th>Interest Pay</th>
                            <th>Total Pay</th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>  

                                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>         

    </div>
    );
  }
}


class StatementToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }
 //calculate principle paid
 calculateTotalPrinciplePaid=()=>{
  var TotalPay=0
  this.props.loanrepayments.map(
    (item)=>{
      TotalPay+=Number(item.principle_paid)
    })

  return TotalPay;
 }

  //calculate Interest paid
  calculateTotalInterestPaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.interest_paid)
      })

    return TotalPay;
   }

         //calculate Total total paid
  calculateTotalTotalPaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.total_paid)
      })

    return TotalPay;
   }

   //calculate total fine paid
   calculateTotalFinePaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.fine_paid)
      })

    return TotalPay;
   }


  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN STATEMENT </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> LOAN NUMBER:  {this.props.loanissueobject.loan_number} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>GRACE PERIOD: {this.props.loanissueobject.grace_period} DAYS</h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Payment mode</th>
                            <th>Desc</th>
                            <th>Principle Paid</th>
                            <th>Interest Paid</th>
                            <th>Fine Paid</th>
                            <th>Total Paid</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>
                            <th>Accrued fine</th>
                            <th>Fined days</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.user==null?item.payment_by:item.user}</td>
                            <td>{item.paymentmethod}</td>

                            <td>{item.description}</td>
                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.fine_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.accrued_fine} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.loanfine_days}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPrinciplePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalFinePaid()} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalTotalPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          </tr>

                      </tbody>
                  </reactstrp.Table>   

                                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>        

    </div>
    );
  }
}


class DailyReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }


  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                <br></br>

                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}>DAILY LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> LOAN NUMBER:  {this.props.loanissueobject.loan_number} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>GRACE PERIOD: {this.props.loanissueobject.grace_period} DAYS</h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

                  <br></br>
                  <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Principle Bf</th>
                          <th>Interest Bf</th>
                          <th>Principle Pay</th>
                          <th>Interest Pay</th>
                          <th>Total Pay</th>
                          <th>Principle Bal</th>
                          <th>Interest Bal</th>

                          <th>Received AMount</th>
                          <th>Receipt No</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.props.loandailyschedules.map(
                        (item)=>(
                          <tr>
                          <td>{item.repayment_number}</td>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.principlebefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.principlepay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          <td></td>

                          </tr>
                        ))}

                    </tbody>
                </reactstrp.Table>
                <br></br>
                <h3>Client Name:......................................................</h3>

                <h3>Officer:......................................................</h3>
                
                <h3>Manager:......................................................</h3>

                <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   
    </div>
    );
  }
}



class LoanIssueDetail extends React.Component {

  state = {
    datetoday:moment().format(dateFormat).toString(),
    loanamount:0,
    interestrate:0,
    interestratetype:'',
    loanperiod:0,
    datarequested:true,
    dateofissuance:moment(),
    dateofissuancestring:moment().format(dateFormat).toString(),
    loanschedule:[],
    loanissueobject:{},
    loanrepayments:[],
    loandailyschedules:[],
    companyprofile:{},
    loanfines:[],

    userprofile:{},
    deletelist:[],
    totaltobepaidobject:{},
    interestpay:0,

    newreschdate:'',
    newreschamt:0,
    waiver_model_visible:false,
    
    
    schedule_edit_interest_value:0,
    schedule_edit_principe_value:0,
    schedule_edit_total_value:0,
    selected_scheduleobject:{},

    payment_model_visible:false,
    interest_paid:0,
    principle_paid:0,
    fine_paid:0,
    total_paid:0,
    interest_balance:0,
    principle_balance:0,
    total_balance:0,
    selected_paymentobject:{},

  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

    }else{
        sacco= ''
        bizuserid= ''
        username=''
    }
  
      
      if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
      }else{
          token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

    const loanissueID=this.props.match.params.loanissueID;
    axios.get(`${serverconfig.backendserverurl}/api/loanissues/${loanissueID}`)
    .then(res => {  
        this.setState({loanissueobject: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loanschedule: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loanrepayments: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loandailyschedules: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({datarequested:false})

    })


    //Now submit sale data to database
    axios.get(serverconfig.backendserverurl+`/customqueries/getdailyloanfines/${loanissueID}/${sacco}`,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res =>{
      console.log(res.data)
      this.setState({loanfines:JSON.parse(res.data.fines)})
    } 
    )
    .catch(error => console.log(error))   

    axios.get(`${serverconfig.backendserverurl}/api/accounts/${bizuserid}`)
    .then(res => {  
        this.setState({
          userprofile: res.data
        })
    })


  }


  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.loanschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal;
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.loanschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal;
    }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay;
    }


      //calculate principle paid
      calculateTotalPrinciplePaid=()=>{
      var TotalPay=0
      this.state.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.principle_paid)
        })
  
      return TotalPay;
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay;
       }

             //calculate Total total paid
      calculateTotalTotalPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.total_paid)
          })
    
        return TotalPay;
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay;
       }


      //calculate total fine paid
      calculateTotaldailyFine=()=>{
        var TotalPay=0
        this.state.loanfines.map(
          (item)=>{
            TotalPay+=Number(item.cyclefine)
          })
    
        return TotalPay;
        }

  render(){
   
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>           </div>
      )

    }else{

      return (
        <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            loanschedule={this.state.loanschedule} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={el => (this.componentRef = el)} /> 
            </div>

            &nbsp;&nbsp;
            <div style={{display: "none"}}>
            <DailyReceiptToPrint
            loandailyschedules={this.state.loandailyschedules} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={el => (this.componentRefthree = el)} /> 
            </div>


            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <StatementToPrint
            loanrepayments={this.state.loanrepayments} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={sel => (this.componentReftwo = sel)} /> 
            </div>

            <Row >

                    <Col xs="12" sm="6" lg="8">
                    <Card style={{overflowX : 'auto'}}>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN SCHEDULE</h4>

                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Principle Bf</th>
                            <th>Principle Pay</th>
                            <th>Interest Pay</th>
                            <th>Total Pay</th>
                            <th>Principle Bal</th>
                          
                            { 
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>Action</th>
                            :
                            null
                            }

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                            { 
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                           <td>
                           <Tooltip title="Click to edit interest (waive)" placement="top">
                            <EditOutlined style={{color:'blue'}}
                            onClick={()=>{

                              this.setState({schedule_edit_interest_value:item.interest})
                              this.setState({schedule_edit_principe_value:item.principle})

                              this.setState({selected_scheduleobject:item})
                              this.setState({waiver_model_visible:true})
                              
                            }}
                            />
                            </Tooltip>
                           </td>
                           :
                           null}

                           
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>
                  <br></br>
                  <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

                  <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 style={{alignSelf:'center'}}>Print Schedule</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentRef}
                          />  
                          </a>
                  </div>
 
              </Card>
              </Col>
              <Col xs="12" sm="6" lg="4">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:'salmon'}}>ISSUED LOAN DETAILS</h4>
                    <h6 style={{fontWeight:'bold'}}>Loan Amount:<CurrencyFormat value={this.state.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX </h6>
                    <h6 style={{fontWeight:'bold'}}>Issuance Date: {this.state.loanissueobject.date}</h6>
                    <h6 style={{fontWeight:'bold'}}>Loan Period: {this.state.loanissueobject.loan_period}</h6>
                    <h6 style={{fontWeight:'bold'}}>Grace Period: {this.state.loanissueobject.grace_period} days</h6>

                    <h6 style={{fontWeight:'bold'}}>Interest Rate: {this.state.loanissueobject.interest_rate}</h6>
                    <h6 style={{fontWeight:'bold'}}>Interest Rate Type: {this.state.loanissueobject.interest_rate_type}</h6>

                    <h6 style={{fontWeight:'bold'}}>Account Number: {this.state.loanissueobject.accountNumber}</h6>
                    <h6 style={{fontWeight:'bold'}}>Account Name: {this.state.loanissueobject.accountName}</h6>

                    <br></br>
                    <h5 style={{color:'red'}}>NOTE</h5>
                    <h6>Flat Interest gives more profits as compared to reducing balance</h6>
                    <h6>Incase we round,we make sure loan principle balances at the last payment</h6>

                    </Card>
                    </Col>
            </Row>
            <br></br>


            {
              this.state.loanissueobject.dailyschedulegenerated===true?
                  <Row >

                  <Col xs="12" sm="8" lg="12">
                  <Card style={{overflowX : 'auto'}}>
                  <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN DAILY SCHEDULE</h4>

                  <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Principle Bf</th>
                          <th>Interest Bf</th>
                          <th>Principle Pay</th>
                          <th>Interest Pay</th>
                          <th>Total Pay</th>
                          <th>Principle Bal</th>
                          <th>Interest Bal</th>
                          <th>Principle Cleared</th>
                          <th>Interest Cleared</th>

                          {
                            this.state.companyprofile.allow_transaction_editing===true?
                            <th>Mark Paid</th>
                            :null
                          }


                          {
                            this.state.companyprofile.allow_transaction_editing===true?
                            <th>Reset</th>
                            :null
                          }



                        </tr>
                      </thead>
                      <tbody>
                      {this.state.loandailyschedules.map(
                        (item)=>(
                          <tr>
                          <td>{item.repayment_number}</td>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.principlebefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.principlepay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbalance} displayType={'text'} thousandSeparator={true}/>}</td>

                          <th>{item.principleCleared===true?"Yes":"No"}</th>
                          <th>{item.interestCleared===true?"Yes":"No"}</th>

                          {
                            this.state.companyprofile.allow_transaction_editing===true?
                            <th>

                            <Button type="primary" onClick={()=>{

                              let form_data = new FormData();
                              form_data.append('scheduleid', item.id);
                              form_data.append('action', "markpaid");

                              //Now submit sale data to database
                              axios.post(serverconfig.backendserverurl+'/customqueries/resetdailyschedule', form_data,{
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                              .then(res =>{
                                const loanissueID=this.props.match.params.loanissueID;

                                axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
                                .then(res => {  
                                    this.setState({loandailyschedules: res.data})
                                })

                               })
                              .catch(error => console.log(error)) 

                            }}>
                              Mark 
                            </Button>
                            </th>

                            :null
                          }



                          {
                            this.state.companyprofile.allow_transaction_editing===true?
                            <th>

                            <Button type="primary" onClick={()=>{

                              let form_data = new FormData();
                              form_data.append('scheduleid', item.id);
                              form_data.append('action', "reset");

                              //Now submit sale data to database
                              axios.post(serverconfig.backendserverurl+'/customqueries/resetdailyschedule', form_data,{
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                              .then(res =>{
                                const loanissueID=this.props.match.params.loanissueID;

                                axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
                                .then(res => {  
                                    this.setState({loandailyschedules: res.data})
                                })

                               })
                              .catch(error => console.log(error)) 

                            }}>
                              Reset 
                            </Button>
                            </th>

                            :null
                          }
                          

                          </tr>
                        ))}

                    </tbody>
                </reactstrp.Table>
                <br></br>
                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

                <a>
                    <ReactToPrint
                      trigger={() =>                     
                        <div style={{}}>
                          <Tooltip title="Click to print" placement="top">
                          <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                          <h6 style={{alignSelf:'center'}}>Print Daily Schedule</h6>
                          </Tooltip>
                        </div>                          
                          }
                          content={() => this.componentRefthree}
                        />  
                </a>
                </div>

            </Card>
            </Col>
          </Row>

            :
            null

          }



            <br></br>
            <Row >
            <Col xs="12" sm="6" lg="12">
                    <Card style={{overflowX : 'auto'}}>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN STATEMENT</h4>
                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Payment mode</th>

                            <th>Desc</th>
                            <th>Principle Paid</th>
                            <th>Interest Paid</th>
                            <th>Fine Paid</th>
                            <th>Total Paid</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>

                            <th>Accrued fine</th>
                            <th>Fined days</th>


                            {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>Select Payment</th>
                            :
                            null
                           }

                          { 
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>Edit Payment</th>
                            :
                            null
                           }

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.user==null?item.payment_by:item.user}</td>
                            <td>{item.paymentmethod}</td>
                            <td>{item.description}</td>

                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.fine_paid} displayType={'text'} thousandSeparator={true}/>}</td>

                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                           
                            <td>{<CurrencyFormat value={item.accrued_fine} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.loanfine_days}</td>

                            {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>
                              
                            <PlusOutlined  style={{color:this.state.deletelist.indexOf(item.id)> -1?'red':'green'}}  onClick={()=>{

                              if(this.state.deletelist.indexOf(item.id)> -1){
                                console.log("trans removed")
                                const newList = this.state.deletelist.filter((trans) => trans!== item.id);
                                this.setState({deletelist:newList});
                              }else{

                                this.setState({
                                  deletelist: [...this.state.deletelist, item.id]
                                  });

                                  console.log("trans added")

                              }
                                
                            }}/>

                            </th>
                            :
                            null
                          }


                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>
                           <Tooltip title="Click to edit payment entry" placement="top">
                            <EditOutlined style={{color:'blue'}}
                            onClick={()=>{

                              this.setState({interest_paid:item.interest_paid})
                              this.setState({principle_paid:item.principle_paid})
                              this.setState({fine_paid:item.fine_paid})
                              this.setState({interest_balance:item.interest_balance})
                              this.setState({total_balance:item.loan_balance})
                              this.setState({principle_balance:item.principle_balance})

                              this.setState({selected_paymentobject:item})
                              this.setState({payment_model_visible:true})
                              
                            }}
                            />
                            </Tooltip>
                           

                            </th>
                            :
                            null
                          }
                           
                           
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPrinciplePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalFinePaid()} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalTotalPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td></td>
                          <td></td>


                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <td></td>
                            :
                            null
                          }

                          </tr>

                      </tbody>
                  </reactstrp.Table>
                 
                  <br></br>
                  <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

                  {
                   this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                  <Button  type="primary" htmlType='button'
                  onClick={()=>{
                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('deletemode','loanrepayments');
                    form_data.append('deletelist',JSON.stringify(this.state.deletelist) );

                    axios.post(serverconfig.backendserverurl+'/customqueries/massdeleting', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      //get new data back
                      this.setState({datarequested:false})
                      this.setState({deletelist:[]})
                      window.location.reload(false)

                    })
                    .catch(error => console.log(error))

                  }}
                  
                  >Batch Delete</Button>
                  :
                  null
                  }

                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;

                  <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 style={{alignSelf:'center'}}>Print Statement</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentReftwo}
                          />  
                  </a>



                <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Excel</Button>}>
                <ExcelSheet data={this.state.loanrepayments} name="Loan statement">
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Desc" value="description"/>
                    <ExcelColumn label="User" value="payment_by"/>
                    <ExcelColumn label="Paymentmethod" value="paymentmethod"/>
                    
                    <ExcelColumn label="principle_paid" value="principle_paid"/>
                    <ExcelColumn label="interest_paid" value="interest_paid"/>
                    <ExcelColumn label="fine_paid" value="fine_paid"/>
                    <ExcelColumn label="total_paid" value="total_paid"/>
                    <ExcelColumn label="principle_balance" value="principle_balance"/>
                    <ExcelColumn label="interest_balance" value="interest_balance"/>
                    <ExcelColumn label="loan_balance" value="loan_balance"/>
                    <ExcelColumn label="loan_id" value={this.state.loanissueobject.id}/>

                </ExcelSheet>
                </ExcelFile>

                </div>
              </Card>
                    
            </Col>
            
            </Row>



            <br></br>

            {
              this.state.companyprofile.calculate_dailyloandefaultfine===true?
              <Row >
              {/*<Col xs="12" sm="6" lg="12">
                      <Card>
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN DAILY FINES CALCULATOR</h4>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Cycle Due Date</th>
                              <th>Passed Days</th>
                              <th>Cycle Balance</th>
                              <th>Fine</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.loanfines.map(
                            (item)=>(
                              <tr>
                              <td>{item.cycleduedate}</td>
                              <td>{item.pastdays}</td>
                              <td>{<CurrencyFormat value={item.totalbal} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.cyclefine} displayType={'text'} thousandSeparator={true}/>}</td>
                              </tr>
                            ))}
                            <tr>
                            <td></td>
                            <td></td>
                            <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={0} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotaldailyFine()} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
  
                        </tbody>
                    </reactstrp.Table>
  
                </Card>
                      
              </Col> */}
              
              </Row>  
              :
              null
            }

          <Modal              
              visible={this.state.waiver_model_visible}
              title={"Schedule edit form"}
              onCancel={()=>{this.setState({waiver_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({waiver_model_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{

                        let form_data = new FormData();
                        form_data.append('schedule_id', this.state.selected_scheduleobject.id);
                        form_data.append('principle', this.state.schedule_edit_principe_value);
                        form_data.append('interest', this.state.schedule_edit_interest_value);
                        form_data.append('total', Number(this.state.schedule_edit_principe_value)+Number(this.state.schedule_edit_interest_value));

                        axios.post(serverconfig.backendserverurl+`/customqueries/schedule_editing`, form_data, {
                          headers: {
                            'content-type': 'multipart/form-data'
                          }
                        })
                      .then(res => {
                        if(res.data.message=="success"){
                          message.info(res.data.message)
                          this.setState({datarequested:false})
                          window.location.reload(false)
                    
                        }else{
                          message.info("Some thing went wrong")
                        }
                    
                      } )
                      .catch(error => console.log(error))
                      this.setState({waiver_model_visible:false})

                    }}>
                  Update
                </Button>
                ]}
                >
                  
                <FormItem label="New Interest value" >
                    <Input name="schedule_edit_interest_value"  placeholder="New Interest value" value={this.state.schedule_edit_interest_value} onChange={(val)=>{this.setState({schedule_edit_interest_value:val.target.value})}} />
                </FormItem>             

                <FormItem label="New principle value" >
                    <Input name="schedule_edit_principe_value"  placeholder="New Principle value" value={this.state.schedule_edit_principe_value} onChange={(val)=>{this.setState({schedule_edit_principe_value:val.target.value})}} />
                </FormItem>      
        
               </Modal> 




               <Modal              
              visible={this.state.payment_model_visible}
              title={"Loan payment edit form"}
              onCancel={()=>{this.setState({payment_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({payment_model_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{

                        let form_data = new FormData();
                        form_data.append('payment_id', this.state.selected_paymentobject.id);
                        form_data.append('interest_paid', this.state.interest_paid);
                        form_data.append('principle_paid', this.state.principle_paid);
                        form_data.append('fine_paid', this.state.fine_paid);
                        form_data.append('username', username);

                        axios.post(serverconfig.backendserverurl+`/customqueries/loanpayment_editing`, form_data, {
                          headers: {
                            'content-type': 'multipart/form-data'
                          }
                        })
                      .then(res => {
                        if(res.data.message=="success"){
                          message.info(res.data.message)
                          this.setState({datarequested:false})
                          window.location.reload(false)
                    
                        }else{
                          message.info("Some thing went wrong")
                        }
                    
                      } )
                      .catch(error => console.log(error))
                      this.setState({payment_model_visible:false})

                    }}>
                  Update
                </Button>
                ]}
                >
                  
                <FormItem label="Interest paid" >
                    <Input name="interest_paid"  placeholder="Interest paid" value={this.state.interest_paid} onChange={(val)=>{this.setState({interest_paid:val.target.value})}} />
                </FormItem>             

                <FormItem label="Principle paid" >
                    <Input name="principle_paid"  placeholder="Principle paid" value={this.state.principle_paid} onChange={(val)=>{this.setState({principle_paid:val.target.value})}} />
                </FormItem>      
        
                <FormItem label="Fine paid" >
                    <Input name="fine_paid"  placeholder="Fine paid" value={this.state.fine_paid} onChange={(val)=>{this.setState({fine_paid:val.target.value})}} />
                </FormItem>    

               </Modal> 
        </div>
      );

    }

  }

}


export default LoanIssueDetail;



